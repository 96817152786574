import { matchPath } from 'react-router-dom';

import { LazyRoute, RouterItem } from 'components/routing/LazyRoute';

import { JchUser } from 'api';
import { UniVariant } from 'theme';

import { isAdminUser, isRecruiterUser, isStudentUser } from './token';
import Lazy from './lazyComps';

const PUBLIC_ROUTES: RouterItem[] = [
	{
		type: 'route',
		path: '/',
		render: LazyRoute(Lazy.Homepage),
	},
	{
		type: 'route',
		path: '/public/:anchor',
		render: LazyRoute(Lazy.Homepage),
	},
	// External company offer routes
	{
		type: 'route',
		path: '/offer/:id/:token',
		render: LazyRoute(Lazy.OfferDetailExternal),
	},
	{
		type: 'route',
		path: '/offer/edit/:id/:token',
		render: LazyRoute(Lazy.OfferEditExternal),
	},
];

const LOGIN_ROUTES: RouterItem[] = [
	{
		type: 'route',
		path: '/login',
		render: LazyRoute(Lazy.Login),
	},
	{
		type: 'route',
		path: '/login/company',
		render: LazyRoute(Lazy.LoginCompany),
	},
	{
		type: 'route',
		path: '/forgottenPassword/company',
		render: LazyRoute(Lazy.ForgottenPassword),
	},
	{
		type: 'redirect',
		path: '/registration/company',
		to: '/registration/company/0',
	},
	{
		type: 'route',
		path: '/registration/company/:step',
		render: LazyRoute(Lazy.CompanyRegistration),
	},
	{
		type: 'route',
		path: '/password/new/:token',
		render: LazyRoute(Lazy.ResetPassword),
	},
	{
		type: 'route',
		path: '/login/alumnus',
		render: LazyRoute(Lazy.LoginAlumnus),
	},
	{
		type: 'redirect',
		path: '/registration/alumnus',
		to: '/registration/alumnus/0',
	},
	{
		type: 'route',
		path: '/registration/alumnus/:step',
		render: LazyRoute(Lazy.AlumnusRegistration),
	},
	{
		type: 'route',
		path: '/forgottenPassword/alumnus',
		render: LazyRoute(Lazy.ForgottenPassword),
	},
	{
		type: 'route',
		path: '/admin',
		render: LazyRoute(Lazy.LoginAdmin),
	},
	/* czech redirect for SEO */
	{
		type: 'redirect',
		path: '/prihlaseni',
		to: '/login',
	},
	{
		type: 'redirect',
		path: '/prihlaseni/zamestnavatel',
		to: '/login/company',
	},
	{
		type: 'redirect',
		path: '/prihlaseni/absolvent',
		to: '/login/alumnus',
	},
	{
		type: 'redirect',
		path: '/registrace/zamestnavatel',
		to: '/registration/company/0',
	},
	{
		type: 'redirect',
		path: '/registrace/absolvent',
		to: '/registration/alumnus/0',
	},
];

const COMPANY_ROUTES: RouterItem[] = [
	{
		type: 'redirect',
		path: '/',
		to: '/dashboard',
	},
	{
		type: 'route',
		path: '/dashboard/:tab?',
		render: LazyRoute(Lazy.CompanyDashboard),
	},
	{
		type: 'route',
		path: '/offers',
		render: LazyRoute(Lazy.CompanyOffers),
	},
	{
		type: 'route',
		path: '/offer/new',
		render: LazyRoute(Lazy.CompanyOfferNew),
	},
	{
		type: 'route',
		path: '/offer/edit/:id',
		render: LazyRoute(Lazy.CompanyOfferEdit),
	},
	{
		type: 'route',
		path: '/offer/candidates/:id',
		render: LazyRoute(Lazy.OfferCandidates),
	},
	{
		type: 'route',
		path: '/orders',
		render: LazyRoute(Lazy.CompanyOrders),
	},
	{
		type: 'route',
		path: '/orders/new/:step',
		render: LazyRoute(Lazy.CompanyOrderNew),
	},
	{
		type: 'route',
		path: '/settings',
		render: LazyRoute(Lazy.CompanySettings),
	},
	{
		type: 'route',
		path: '/company/edit',
		render: LazyRoute(Lazy.CompanyProfileEdit),
	},
	{
		type: 'route',
		path: '/company',
		render: LazyRoute(Lazy.CompanyDetailOwn),
	},
	{
		type: 'route',
		path: '/offer/:id',
		render: LazyRoute(Lazy.OfferDetail),
	},
	{
		type: 'route',
		path: '/student/:id',
		render: LazyRoute(Lazy.StudentDetailOther),
	},
	{
		type: 'route',
		path: '/search/students',
		render: LazyRoute(Lazy.CompanySearchStudents),
	},
	{
		type: 'route',
		path: '/notifications',
		render: LazyRoute(Lazy.Notifications),
	},
	{
		type: 'route',
		path: '/password/new/:token/:internal?',
		render: LazyRoute(Lazy.ResetPassword),
	},
];

const UNREGISTERED_STUDENT_ROUTES: RouterItem[] = [
	{
		type: 'redirect',
		path: '/dashboard',
		to: '/registration/student/0',
	},
	{
		type: 'redirect',
		path: '/',
		to: '/registration/student/0',
	},
	{
		type: 'route',
		path: '/registration/student/:step',
		render: LazyRoute(Lazy.StudentRegistration),
	},
];

const ZLIN_ROUTES: RouterItem[] = [
	{
		type: 'route',
		path: '/homepage',
		render: LazyRoute(Lazy.Homepage),
	},
	{
		type: 'route',
		path: '/homepage/:anchor',
		render: LazyRoute(Lazy.Homepage),
	},
];

const MUNI_GDPR_ROUTES: RouterItem[] = [
	{
		type: 'route',
		path: '/dashboard',
		render: LazyRoute(Lazy.MissingUniversityGdpr),
	},
	{
		type: 'redirect',
		path: undefined as never,
		to: '/dashboard',
	},
];

const STUDENT_ROUTES: RouterItem[] = [
	{
		type: 'redirect',
		path: '/',
		to: '/dashboard',
	},
	{
		type: 'route',
		path: '/dashboard',
		render: LazyRoute(Lazy.StudentDashboard),
	},
	{
		type: 'route',
		path: '/settings',
		render: LazyRoute(Lazy.StudentSettings),
	},
	{
		type: 'route',
		path: '/student/edit',
		render: LazyRoute(Lazy.StudentProfileEdit),
	},
	{
		type: 'route',
		path: '/student/',
		render: LazyRoute(Lazy.StudentDetailOwn),
	},
	{
		type: 'route',
		path: '/company/:id',
		render: LazyRoute(Lazy.CompanyDetailOther, { follow: true }),
	},
	{
		type: 'route',
		path: '/search/companies',
		render: LazyRoute(Lazy.SearchCompanies),
	},
	{
		type: 'route',
		path: '/offer/:id',
		render: LazyRoute(Lazy.OfferDetail),
	},
	{
		type: 'route',
		path: '/search/offers',
		render: LazyRoute(Lazy.StudentSearchOffers),
	},
	{
		type: 'route',
		path: '/notifications',
		render: LazyRoute(Lazy.Notifications),
	},
	{
		type: 'route',
		path: '/password/new/:token/:internal?',
		render: LazyRoute(Lazy.ResetPassword),
	},
];

const ADMIN_ROUTES: RouterItem[] = [
	{
		type: 'redirect',
		path: '/',
		to: '/dashboard',
	},
	{
		type: 'route',
		path: '/dashboard/:tab?',
		render: LazyRoute(Lazy.AdminDashboard),
	},
	{
		type: 'route',
		path: '/orders',
		render: LazyRoute(Lazy.AdminOrders),
	},
	{
		type: 'route',
		path: '/statistics/:type?/:id?',
		render: LazyRoute(Lazy.AdminStatistics),
	},
	{
		type: 'route',
		path: '/products',
		render: LazyRoute(Lazy.AdminProducts),
	},
	{
		type: 'route',
		path: '/console',
		render: LazyRoute(Lazy.AdminConsole),
	},
	{
		type: 'route',
		path: '/company/:id',
		render: LazyRoute(Lazy.CompanyDetailOther, { editable: true, admin: true }),
	},
	{
		type: 'route',
		path: '/company/edit/:id',
		render: LazyRoute(Lazy.AdminCompanyProfileEdit),
	},
	{
		type: 'route',
		path: '/search/companies',
		render: LazyRoute(Lazy.SearchCompanies),
	},
	{
		type: 'route',
		path: '/offer/:id',
		render: LazyRoute(Lazy.AdminOfferDetail),
	},

	{
		type: 'route',
		path: '/offer/edit/:id',
		render: LazyRoute(Lazy.AdminOfferEdit),
	},
	{
		type: 'route',
		path: '/search/offers',
		render: LazyRoute(Lazy.AdminSearchOffers),
	},
	{
		type: 'route',
		path: '/student/:id',
		render: LazyRoute(Lazy.StudentDetailOther),
	},
	{
		type: 'route',
		path: '/student/edit/:id',
		render: LazyRoute(Lazy.AdminStudentProfileEdit),
	},

	{
		type: 'route',
		path: '/search/students/:type?',
		render: LazyRoute(Lazy.AdminSearchStudents),
	},
	{
		type: 'route',
		path: '/notifications',
		render: LazyRoute(Lazy.Notifications),
	},
];

export const isAuthPath = (pathname: string) =>
	[
		...ADMIN_ROUTES,
		...COMPANY_ROUTES,
		...STUDENT_ROUTES,
		...ZLIN_ROUTES,
	].some(r => (r.type === 'redirect' ? false : matchPath(pathname, r)));

// Get routes based on user authentication
export const getUserRoutes = (
	university: UniVariant,
	user?: Pick<JchUser, 'name' | 'personType'>,
	hasGdpr?: boolean,
) => {
	if (isStudentUser(user)) {
		if (university === 'MUNI' && !hasGdpr) {
			return MUNI_GDPR_ROUTES;
		}

		if (!user.name) {
			return UNREGISTERED_STUDENT_ROUTES;
		}

		if (university === 'ZLIN') {
			return [...STUDENT_ROUTES, ...ZLIN_ROUTES];
		}

		return STUDENT_ROUTES;
	}

	if (isRecruiterUser(user)) {
		if (university === 'ZLIN') {
			return [...COMPANY_ROUTES, ...ZLIN_ROUTES];
		}
		return COMPANY_ROUTES;
	}

	if (isAdminUser(user)) {
		if (university === 'ZLIN') {
			return [...ADMIN_ROUTES, ...ZLIN_ROUTES];
		}
		return ADMIN_ROUTES;
	}

	return [...PUBLIC_ROUTES, ...LOGIN_ROUTES];
};
