/** @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import React from 'react';
import { MdSchedule } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { useQueryCache } from 'react-query';
import { useTranslation } from 'react-i18next';

import { Flex } from 'components/styled';
import Text from 'components/styled/Text';
import { resetButtonStyle } from 'components/styled/Button';

import {
	BasketIcon,
	CheckmarkIcon,
	NotificationsEmptyIcon,
	OfferIcon,
	PackageIcon,
	RevealsIcon,
	SettingsIcon,
} from 'assets';
import { getDateString, getTimeString } from 'utils';
import { useLoggedInUser } from 'api';

import { markNotificationRead } from 'api/notificationsApi';
import { Backend } from 'api/endpoints';

import { NotificationColors } from 'utils/enumMaps';
import { useLocalizedMultistring } from 'utils/localization';

const notificationIcon = (type: Backend.NotificationTemplateType) => {
	switch (type) {
		case 'NEW_RESPONSE_TO_OFFER':
		case 'ACTIVATED_COMPANY':
		case 'REGISTERED_COMPANY_ADMIN':
		case 'REGISTERED_COMPANY':
		case 'REGISTERED_ALUMNUS':
			return <CheckmarkIcon size={16} />;
		case 'ACCEPTED_ORDER':
		case 'REJECTED_ORDER':
		case 'UNPAID_ORDER':
		case 'RECEIVED_NEW_ORDER':
			return <BasketIcon size={20} />;
		case 'ENDING_ACTIVE_LICENSE_IN_ONE_MONTH':
		case 'ENDING_ACTIVE_LICENSE_IN_TWO_WEEKS':
		case 'ENDING_ACTIVE_LICENSE_IN_ONE_WEEK':
		case 'ENDING_ACTIVE_LICENSE_IN_TWO_WEEKS_ADMIN':
		case 'ENDING_ACCESS':
			return <PackageIcon size={20} />;
		case 'DEPLETING_REVEALS':
			return <RevealsIcon size={20} />;
		case 'ENDING_OFFER_IN_ONE_WEEK':
		case 'ENDING_OFFER_TOMORROW':
		case 'DEACTIVATED_OFFER':
		case 'NEW_OFFER_FOLLOWED_COMPANY':
		case 'OFFER_HARDSKILL_REMOVED':
		case 'SUBMITTED_FORM_WAS_READ_BY_COMPANY':
			return <OfferIcon size={20} />;
		case 'NEW_SUGGESTED_HARDSKILL':
		case 'STUDENT_SUGGESTED_HARDSKILL_ACCEPTED':
		case 'STUDENT_SUGGESTED_HARDSKILL_REJECTED':
		case 'STUDENT_HARDSKILL_REMOVED':
		case 'COMPANY_SUGGESTED_HARDSKILL_ACCEPTED':
		case 'COMPANY_SUGGESTED_HARDSKILL_REJECTED':
			return <SettingsIcon color="white" size={16} />;
		default:
			return <NotificationsEmptyIcon size={20} />;
	}
};

const notificationRedirect = (
	type: Backend.NotificationTemplateType,
	customId: string,
) => {
	switch (type) {
		case 'NEW_RESPONSE_TO_OFFER':
			return `/offer/candidates/${customId}`;
		case 'REGISTERED_COMPANY_ADMIN':
			return `/company/${customId}`;
		case 'ACTIVATED_COMPANY':
		case 'REGISTERED_COMPANY':
			return '/company';
		case 'REGISTERED_ALUMNUS':
			return '/student';
		case 'ACCEPTED_ORDER':
		case 'REJECTED_ORDER':
		case 'UNPAID_ORDER':
		case 'RECEIVED_NEW_ORDER':
		case 'ENDING_ACTIVE_LICENSE_IN_ONE_MONTH':
		case 'ENDING_ACTIVE_LICENSE_IN_TWO_WEEKS':
		case 'ENDING_ACTIVE_LICENSE_IN_ONE_WEEK':
		case 'ENDING_ACTIVE_LICENSE_IN_TWO_WEEKS_ADMIN':
			return `/orders?id=${customId}`;
		case 'ENDING_ACCESS':
		case 'DEPLETING_REVEALS':
			return '/orders/new/0';
		case 'ENDING_OFFER_IN_ONE_WEEK':
		case 'ENDING_OFFER_TOMORROW':
		case 'DEACTIVATED_OFFER':
		case 'NEW_OFFER_FOLLOWED_COMPANY':
		case 'OFFER_HARDSKILL_REMOVED':
		case 'SUBMITTED_FORM_WAS_READ_BY_COMPANY':
			return `/offer/${customId}`;
		case 'NEW_SUGGESTED_HARDSKILL':
			return `/dashboard/hardskills?id=${customId}`;
		case 'STUDENT_SUGGESTED_HARDSKILL_ACCEPTED':
		case 'STUDENT_SUGGESTED_HARDSKILL_REJECTED':
		case 'STUDENT_HARDSKILL_REMOVED':
			return '/student/edit';
		case 'COMPANY_SUGGESTED_HARDSKILL_ACCEPTED':
		case 'COMPANY_SUGGESTED_HARDSKILL_REJECTED':
			return '/offers';
		default:
			return '/notifications';
	}
};

type Props = Backend.Notification & {
	variant: 'dashboard' | 'header';
	afterClick?: () => void;
};

const NotificationItem: React.FC<Props> = ({
	variant,
	id,
	created,
	title,
	content,
	customId,
	read,
	templateType,
	afterClick,
}) => {
	const { push } = useHistory();
	const { t, i18n } = useTranslation('/common');
	const localize = useLocalizedMultistring();
	const cache = useQueryCache();
	const user = useLoggedInUser();

	return (
		<Flex
			as="button"
			alignItems="center"
			color={!read ? 'text' : 'textLight'}
			p={0}
			my={variant === 'header' ? 2 : 0}
			onClick={async () => {
				await markNotificationRead(id, user?.personType);
				await cache.invalidateQueries('notifications');
				push(notificationRedirect(templateType, customId));
				afterClick?.();
			}}
			css={css`
				${resetButtonStyle}
				text-align: left;
			`}
		>
			{/* Icon */}
			<Flex
				mr={3}
				flexShrink={0}
				size={35}
				justifyContent="center"
				color="white"
				alignItems="center"
				bg={NotificationColors[templateType]}
				css={css`
					border-radius: 50%;
				`}
			>
				{notificationIcon(templateType)}
			</Flex>

			{/* Content */}
			<Flex
				flexDirection="column"
				css={css`
					&:hover {
						text-decoration: underline;
					}
				`}
			>
				<Text
					fontWeight="bold"
					m={0}
					css={css`
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					`}
				>
					{localize(title)}
				</Text>
				<Text whiteSpace="pre-line" lineHeight={1}>
					{localize(content)}
				</Text>
			</Flex>

			{/* Time */}
			{variant === 'dashboard' && (
				<Flex
					justifyContent="flex-end"
					alignSelf="flex-start"
					flexGrow={1}
					flexShrink={0}
					ml={2}
					color="textLight"
					css={(theme: Theme) => css`
						@media (max-width: ${theme.breakpoints[0]}) {
							display: none;
						}
					`}
				>
					<Flex alignItems="center">
						<MdSchedule />
						<Text ml={2}>
							{`${getDateString(created, i18n.language)} ${t(
								'at',
							)} ${getTimeString(created, i18n.language)}`}
						</Text>
					</Flex>
				</Flex>
			)}
		</Flex>
	);
};
export default NotificationItem;
