import React, { useMemo } from 'react';
import { IconType } from 'react-icons/lib';
import {
	FaEnvelope,
	FaFacebookF,
	FaGlobe,
	FaInstagram,
	FaLinkedin,
	FaPhone,
	FaTwitter,
} from 'react-icons/fa';

import { NavHrefButton } from 'components/styled/Button';

import { normalizeUrl } from 'utils';

type LinkLabels =
	| 'Website'
	| 'Phone'
	| 'Mail'
	| 'LinkedIn'
	| 'Facebook'
	| 'Instagram'
	| 'Twitter';

const LinkIcons: Record<LinkLabels, IconType> = {
	Website: FaGlobe,
	Phone: FaPhone,
	Mail: FaEnvelope,
	LinkedIn: FaLinkedin,
	Facebook: FaFacebookF,
	Instagram: FaInstagram,
	Twitter: FaTwitter,
};

const SocialLink: React.FC<{
	label: LinkLabels;
	href: string;
}> = ({ label, href }) => {
	const url = useMemo(
		() =>
			// eslint-disable-next-line no-nested-ternary
			label === 'Mail'
				? `mailto:${href}`
				: label === 'Phone'
				? `tel:${href}`
				: normalizeUrl(href),
		[label, href],
	);
	const Icon = LinkIcons[label];

	if (!href) {
		return null;
	}

	return (
		<NavHrefButton
			href={url}
			target={label !== 'Mail' && label !== 'Phone' ? '_blank' : ''}
			variant="primary"
			p={2}
			ml={2}
			mt={2}
			minWidth={0}
			size={41}
			title={href}
		>
			<Icon />
		</NavHrefButton>
	);
};

export default SocialLink;
