import React, { FC } from 'react';

import { Box, Flex } from 'components/styled';
import Text from 'components/styled/Text';
import { ResponsivePadder } from 'components/styled/Wrapper';
import SocialLink from 'components/profile/SocialLink';

import { ReactComponent as JobCheckIN } from 'assets/muni/jobcheckin-logo.svg';

import { useLocalizedMultistring } from 'utils/localization';

const MuniFooter: FC = () => {
	const localize = useLocalizedMultistring();

	return (
		<Flex as="footer" bg="lightGrey" flexDirection="column">
			<ResponsivePadder
				flexGrow={1}
				flexDirection={['column', 'row']}
				justifyContent={['center', 'space-between']}
				my={4}
			>
				<Flex
					flexDirection="column"
					textAlign="center"
					alignItems={['center', 'flex-start']}
				>
					<Box color="primary" mb={3} maxWidth={300} width={1}>
						<JobCheckIN />
					</Box>
					<Text my={0}>
						{localize({
							CZECH: 'Kariérní centrum MU',
							ENGLISH: 'Career Centre MU',
						})}
					</Text>
					<Text my={0}>Komenského náměstí 220/2</Text>
					<Text my={0}>602 00 BRNO</Text>
				</Flex>
				<Flex
					flexDirection="column"
					textAlign={['center', 'right']}
					mt={[3, 0]}
				>
					<Box mb={3}>
						<SocialLink label="Website" href="https://kariera.muni.cz/" />
						<SocialLink
							label="LinkedIn"
							href="https://www.linkedin.com/company/kariernicentrum/"
						/>
						<SocialLink
							label="Facebook"
							href="https://www.facebook.com/kc.muni"
						/>
						<SocialLink
							label="Instagram"
							href="https://www.instagram.com/karierko_muni/"
						/>
					</Box>
					<Text my={0}>zamestnavatel@muni.cz</Text>
					<Text my={0}>+420 549 498 270</Text>
				</Flex>
			</ResponsivePadder>
			<Box bg="darkerGrey">
				<ResponsivePadder
					flexDirection={['column', 'row']}
					justifyContent={['center', 'space-between']}
					textAlign={['center', 'left']}
					my={2}
				>
					<Text>
						© 2021{' '}
						{localize({
							CZECH: 'Kariérní centrum MU',
							ENGLISH: 'Career Centre MU',
						})}
					</Text>
					<Text>Created by InQool a.s.</Text>
				</ResponsivePadder>
			</Box>
		</Flex>
	);
};

export default MuniFooter;
