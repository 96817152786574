/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC } from 'react';
import { FaFacebookF } from 'react-icons/fa';

import { Flex } from 'components/styled';
import Text from 'components/styled/Text';
import { ResponsivePadder } from 'components/styled/Wrapper';
import Link, { HrefLink } from 'components/styled/Link';
import Divider from 'components/styled/Divider';

import { useLoggedInUser } from 'api';

import ZlinSymbol from 'assets/zlin/utb-symbol.svg';

import { useLocalizedMultistring } from 'utils/localization';

const ZlinFooter: FC = () => {
	const localize = useLocalizedMultistring();

	const user = useLoggedInUser();

	return (
		<Flex as="footer" bg="secondary" color="white" flexDirection="column">
			<ResponsivePadder
				flexGrow={1}
				flexDirection={['column', 'row']}
				justifyContent={['space-between', 'center']}
				my={4}
			>
				<Flex
					width={[1, 1 / 3]}
					flexDirection="column"
					alignItems={['center', 'flex-start']}
				>
					<Text
						fontSize="lg"
						fontWeight="bold"
						mb={3}
						css={css`
							text-transform: uppercase;
						`}
					>
						{localize({ CZECH: 'Menu', ENGLISH: 'Menu' })}
					</Text>
					<Link to={user ? '/homepage/about' : '/public/about'} color="white">
						{localize({ CZECH: 'O nás', ENGLISH: 'About us' })}
					</Link>
					<Link
						to={user ? '/homepage/student' : '/public/student'}
						color="white"
					>
						{localize({
							CZECH: 'Student / Absolvent',
							ENGLISH: 'Student / Alumnus',
						})}
					</Link>
					<Link
						to={user ? '/homepage/company' : '/public/company'}
						color="white"
					>
						{localize({ CZECH: 'Firmy', ENGLISH: 'Companies' })}
					</Link>
					<Link
						to={user ? '/homepage/contact' : '/public/contact'}
						color="white"
					>
						{localize({ CZECH: 'Kontakt', ENGLISH: 'Contact' })}
					</Link>
				</Flex>

				<Flex
					width={[1, 1 / 3]}
					flexDirection="column"
					alignItems="center"
					mt={[3, 0]}
				>
					<Text
						fontSize="lg"
						fontWeight="bold"
						mb={3}
						css={css`
							text-transform: uppercase;
						`}
					>
						{localize({ CZECH: 'Kontakt', ENGLISH: 'Contact' })}
					</Text>
					<HrefLink href="mailto:jobcentrum@utb.cz" color="primary">
						jobcentrum@utb.cz
					</HrefLink>
					<HrefLink href="tel:+420576032021" color="white">
						+ 420 576 032 021
					</HrefLink>
					<Text my={0}>nám. T. G. Masaryka 5555, Zlín 760 01</Text>
				</Flex>

				<Flex
					width={[1, 1 / 3]}
					flexDirection="column"
					alignItems={['center', 'flex-end']}
					mt={[3, 0]}
				>
					<Text
						fontSize="lg"
						fontWeight="bold"
						mb={3}
						css={css`
							text-transform: uppercase;
						`}
					>
						{localize({ CZECH: 'Newsletter', ENGLISH: 'Newsletter' })}
					</Text>
					<Flex alignItems="center">
						<Flex flexDirection="column" ml={3}>
							<HrefLink
								color="primary"
								//href="https://us16.campaign-archive.com/?e=__test_email__&u=a8cde8fa87db5a0f3773f9af3&id=830bbcadb0"
								href="/newsletter-ls-kurzy-unor-2023.pdf"
								target="_blank"
								my={0}
							>
								{localize({
									CZECH: 'Newsletter Letní semestr 2022/2023',
									ENGLISH: 'Newsletter Summer semester 2022/2023',
								})}
							</HrefLink>
						</Flex>
					</Flex>
				</Flex>
			</ResponsivePadder>

			<ResponsivePadder>
				<Divider color="lightGrey" />
			</ResponsivePadder>

			<ResponsivePadder
				flexDirection={['column', 'row']}
				justifyContent={['space-between', 'center']}
				textAlign={['center', 'left']}
				alignItems="center"
				mt={3}
				mb={4}
			>
				<Flex
					width={[1, 1 / 3]}
					justifyContent={['center', 'flex-start']}
					alignItems="center"
				>
					<HrefLink href="https://www.utb.cz/" target="_blank">
						<Flex alignItems="center" color="white">
							<img src={ZlinSymbol} alt="Logo univerzity" height={36} />
							<Text mx={2}>
								{localize({
									CZECH: 'Univerzita Tomáši Bati ve Zlíně',
									ENGLISH: 'Tomas Bata University in Zlín',
								})}
							</Text>
						</Flex>
					</HrefLink>
				</Flex>

				<Flex width={[1, 1 / 3]} justifyContent="center">
					<HrefLink
						href="https://www.facebook.com/www.jobcentrum.utb.cz"
						target="_blank"
					>
						<Flex alignItems="center" color="white">
							<FaFacebookF />
							<Text ml={2}>
								{localize({
									CZECH: 'Job Centrum UTB',
									ENGLISH: 'Job Centre UTB',
								})}
							</Text>
						</Flex>
					</HrefLink>
				</Flex>

				<Flex width={[1, 1 / 3]} justifyContent={['center', 'flex-end']}>
					<Text>
						{localize({
							CZECH: '© 2021 Portál Job Centrum',
							ENGLISH: '© 2021 Job Centre Potal',
						})}
					</Text>
				</Flex>
			</ResponsivePadder>
		</Flex>
	);
};

export default ZlinFooter;
