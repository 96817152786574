import { formFilterColorVariantType, PartialTheme } from 'theme';

import PortalLogo from 'assets/muni/portal-logo.png';
import { ReactComponent as RegistrationImage } from 'assets/muni/registration-image.svg';

const theme: PartialTheme = {
	university: 'MUNI',
	title: 'JobCheckIN MUNI',
	colors: {
		primary: '#0000DC',
		primaryLight: '#e6e6fc',
		secondary: '#0000DC',
		lightGrey: '#F5F5F5',
		darkerGrey: '#ECECEC',
		text: '#353B4A',
		textLight: '#979AA2',
		chipBackground: '#e6e6fc',
		chipBorder: '#ECECEC',
	},
	fontFamily: 'Neue Haas Unica',
	header: {
		logo: { src: PortalLogo, alt: 'Karierní centrum', height: 71 },
		linkColor: 'primary',
		bg: 'white',
		bgInverted: 'transparent',
		divider: { color: '#EDEDED', size: 2 },
		publicHeaderItems: {
			items: [
				{
					title: {
						ENGLISH: 'For students',
						CZECH: 'Pro studenty',
					},
					to: '/public/student',
				},
				{
					title: {
						ENGLISH: 'For companies',
						CZECH: 'Pro zaměstnavatele',
					},
					to: '/public/company',
				},
			],
			badgeItems: [],
		},
	},
	RegistrationImage: RegistrationImage,
	formFilterColorVariant: 'inverted' as formFilterColorVariantType,
	careerCentreUrl: 'https://kariera.muni.cz/student?locale=cs',
};

export default theme;
