import { Theme, ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { mergeWith } from 'lodash-es';
import React, { FC } from 'react';

import { HeaderItem, UserBadgeItem } from 'components/header';

import { DeepPartial } from 'utils';

import { ReactComponent as RegistrationImage } from 'assets/muni/registration-image.svg';

export type UniVariant = 'MUNI' | 'PLZEN' | 'LIBEREC' | 'ZLIN';

export type formFilterColorVariantType = 'inverted' | 'normal' | undefined;

export type SvgImage = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

const theme = {
	university: 'MUNI' as UniVariant,
	title: '',
	colors: {
		primary: '#01ABA2',
		primaryLight: '#EFF9F8',
		secondary: '#1E2838',
		text: '#000000',
		textLight: '#7D7D7D',
		error: '#FB5353',
		success: '#187E16',
		warning: '#EA9634',
		lightGrey: '#F7F7F7',
		darkerGrey: '#B0B0B0',
		lightGreen: '#5BCCC6',
		darkGreen: '#0A8780',
		golden: '#ff9900C7',
		chipBackground: '#EFF9F8',
		chipBorder: '#B0B0B0',
	},
	breakpoints: ['40em', '52em', '64em', '76em'],
	fontSizes: { sm: 14, md: 16, lg: 20, xl: 28 },
	fontFamily: 'Roboto',
	space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
	header: {
		shortLanguages: false,
		logo: { src: '', alt: '', height: 40 },
		logoMobile: { src: '', alt: '', height: 40 },
		uniLogo: { src: '', alt: '', height: 40 },
		PortalLogoSvg: (null as unknown) as SvgImage,
		UniLogoSvg: (null as unknown) as SvgImage,
		uniLogoMobile: { src: '', alt: '', height: 40 },
		linkColor: 'text',
		bg: 'white',
		bgInverted: 'primary',
		divider: { color: 'lightGrey', size: 1 },
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		publicHeaderItems: {
			items: [] as HeaderItem[],
			badgeItems: [] as UserBadgeItem[],
		},
	},
	RegistrationImage: RegistrationImage,
	formFilterColorVariant: undefined as formFilterColorVariantType,
	careerCentreUrl: '#',
};

type ThemeType = typeof theme;

declare module '@emotion/react' {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions, @typescript-eslint/no-empty-interface
	export interface Theme extends ThemeType {}
}

export type PartialTheme = DeepPartial<Theme>;

export const ThemeProvider: FC<{
	themeOverrides?: PartialTheme;
}> = ({ themeOverrides = {}, children }) => (
	<EmotionThemeProvider theme={mergeWith({}, theme, themeOverrides)}>
		{children}
	</EmotionThemeProvider>
);

export * from './GlobalStyles';
export { default as GlobalStyles } from './GlobalStyles';
