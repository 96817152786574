import ky, { ResponsePromise } from 'ky';
import {
	QueryCache,
	QueryConfig,
	useInfiniteQuery,
	useQuery,
} from 'react-query';
import { createContext, useCallback, useContext, useMemo } from 'react';

import { Backend } from 'api/endpoints';

import { EASParams, EASResult } from 'utils/EASTypes';
import { PrefixFromPersonType } from 'utils/enumMaps';

export const QueryCacheInstance = new QueryCache();

export type JchUser = Backend.JchUserContext &
	Pick<Backend.JchUser, 'id' | 'name'>;

export const api = (prefix?: string, json?: EASParams) =>
	ky.extend({
		prefixUrl: `/api/jch${prefix ?? ''}`,
		timeout: 30000,
		...(json ? { json } : {}),
		hooks: {
			afterResponse: [
				// Handle session expiration
				async (request, _, response) => {
					if (response.status === 403) {
						await QueryCacheInstance.invalidateQueries(['me']);
						return ky(request);
					}
					return;
				},
			],
		},
	});

const UserContext = createContext<JchUser | undefined>(undefined);

export const useLoggedInUserProvider = () => {
	const userResponse = useQuery(
		'me',
		useCallback(
			() =>
				api()
					.get('public/me')
					.json<Backend.JchUser>()
					.catch(() => undefined),
			[],
		),
		{
			staleTime: Infinity,
		},
	);

	return { userResponse, UserContextProvider: UserContext.Provider };
};

export const useLoggedInUser = () => useContext(UserContext);

export const authEndpoint = <T, Args extends unknown[] = []>(
	key: string[],
	promise: (a: ReturnType<typeof api>, ...args: Args) => ResponsePromise,
	queryConfig?: QueryConfig<T>,
) => (...args: Args) => {
	const user = useLoggedInUser();
	return useQuery(
		[...key, ...args],
		() =>
			promise(
				api(PrefixFromPersonType[user?.personType ?? 'PUBLIC']),
				...args,
			).json<T>(),
		queryConfig,
	);
};

export const infiniteEndpoint = <T, Args extends unknown[] = []>(
	key: string[],
	promise: (a: ReturnType<typeof api>, ...args: Args) => ResponsePromise,
	pageSize = 10,
) => (...args: Args) => {
	const user = useLoggedInUser();
	const result = useInfiniteQuery(
		[...key, ...args],
		(...requestArgs) => {
			return promise(
				api(user && PrefixFromPersonType[user.personType], {
					searchAfter: requestArgs[requestArgs.length - 1] as
						| string
						| undefined,
					size: pageSize,
				}),
				...args,
			).json<EASResult<T>>();
		},
		{
			getFetchMore: (p, all) =>
				all[0]?.count > all.flatMap(i => i.items).length
					? p.searchAfter
					: undefined,
		},
	);

	const data = useMemo(() => result.data?.flatMap(i => i.items), [result.data]);
	const page = useMemo(() => {
		if (!result.data || result.data.length <= 0) {
			return 0;
		}

		return Math.ceil(result.data.flatMap(i => i.items).length / pageSize);
	}, [result.data]);

	return {
		...result,
		data,
		count: result.data?.[0]?.count,
		page,
		hasMore: !!result.canFetchMore,
	};
};
