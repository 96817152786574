import { formFilterColorVariantType, PartialTheme } from 'theme';

import PortalLogo from 'assets/zlin/job_portal_logo.svg';
import CentrumLogo from 'assets/zlin/job_centrum_logo.svg';
import PortalSymbol from 'assets/zlin/job_portal_symbol.svg';
import CentrumSymbol from 'assets/zlin/job_centrum_symbol.svg';
import { ReactComponent as RegistrationImage } from 'assets/zlin/registration-image.svg';

const theme: PartialTheme = {
	university: 'ZLIN',
	title: 'Job Centrum - UTB',
	colors: {
		primary: '#FF6F07',
		primaryLight: '#F1F4F7',
		secondary: '#494949',
		lightGrey: '#F1F4F7',
		darkerGrey: '#EDEDED',
		text: '#494949',
		textLight: '#C4C4C4',
		chipBackground: 'white',
		chipBorder: '#FF6F07',
	},
	fontFamily: 'Source Sans Pro',
	header: {
		logo: { src: PortalLogo, alt: 'Job Portal' },
		logoMobile: { src: PortalSymbol, alt: 'Job Portal' },
		uniLogo: { src: CentrumLogo, alt: 'Job Centrum' },
		uniLogoMobile: { src: CentrumSymbol, alt: 'Job Centrum' },
		publicHeaderItems: {
			items: [
				{
					title: {
						ENGLISH: 'About us',
						CZECH: 'O nás',
					},
					to: '/public/about',
				},
				{
					title: {
						ENGLISH: 'Event calendar',
						CZECH: 'Kalendář akcí',
					},
					to: {
						pathname: 'https://calendar.jobcentrum.utb.cz/',
					},
					target: '_blank',
				},
				{
					title: {
						ENGLISH: 'Student / Alumnus',
						CZECH: 'Student / Absolvent',
					},
					to: '/public/student',
				},
				{
					title: {
						ENGLISH: 'Company',
						CZECH: 'Firma',
					},
					to: '/public/company',
				},
				{
					title: {
						ENGLISH: 'Contact',
						CZECH: 'Kontakt',
					},
					to: '/public/contact',
				},
			],
			badgeItems: [],
		},
	},
	RegistrationImage: RegistrationImage,
	formFilterColorVariant: 'normal' as formFilterColorVariantType,
	careerCentreUrl: 'https://jobcentrum.utb.cz/',
};

export default theme;
