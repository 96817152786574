/** @jsxImportSource @emotion/react */
import { css, Theme, useTheme } from '@emotion/react';
import { FC, Fragment, ReactNode, useMemo } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Grid } from 'components/styled';
import { ResponsivePadder } from 'components/styled/Wrapper';
import { OffscreenText } from 'components/styled/Text';
import { NavLink } from 'components/styled/Link';
import Divider from 'components/styled/Divider';

import { useLoggedInUser } from 'api';

import { getUserHeaderItems } from 'auth/headerMenu';

import { useLocalizedMultistring } from 'utils/localization';
import { EASMultilanguage } from 'utils/EASTypes';

import NotificationsBadge from './NotificationsBadge';
import SideMenu from './SideMenu';
import UserBadge from './UserBadge';
import SideNotificationsMenu from './SideNotificationsMenu';
import LanguageSwitch from './LanguageSwitch';
import ZlinHeader from './ZlinHeader';

export type HeaderItem = {
	title: EASMultilanguage;
	to: LinkProps<unknown>['to'];
	target?: LinkProps<unknown>['target'];
};

export type UserBadgeItem = {
	title: EASMultilanguage;
	icon?: ReactNode;
	to: string;
};

// FIXME: Cleanup - mainly nested ternary operators in logo section
export const HEADER_WRAPPER_ID = 'page-header';

const Header: FC = () => {
	const localize = useLocalizedMultistring();
	const { t } = useTranslation('components/header/index/');

	const user = useLoggedInUser();

	// Theme
	const { university, title, header, colors } = useTheme();
	const { badgeItems, items } = useMemo(
		() => (user ? getUserHeaderItems(user) : header.publicHeaderItems),
		[user, header.publicHeaderItems],
	);

	const UniLogoSvg = header?.UniLogoSvg ?? <></>;
	const PortalLogoSvg = header?.PortalLogoSvg ?? <></>;

	const { pathname } = useLocation();
	const isHomepage = pathname === '/' || pathname.includes('/public/');
	const inverted =
		(university === 'MUNI' && isHomepage) ||
		(university === 'PLZEN' && isHomepage);

	const linkColor = inverted ? 'white' : header.linkColor;

	// Login link styling for different orgs
	const loginButtonOrg =
		(university === 'MUNI' &&
			css`
				border: 1px solid white;
			`) ||
		(university === 'ZLIN' &&
			css`
				border: 1px solid white;
				background-color: ${colors.primary};
				color: white;
				&:hover {
					background-color: white;
					border-color: ${colors.primary};
					color: ${colors.primary};
					text-decoration: none;
				}
			`);

	// Other menu links
	const navLinkOrg =
		university === 'ZLIN' &&
		css`
			&:hover {
				text-decoration: none;
				color: ${colors.primary};
			}
		`;

	return (
		<>
			{university === 'ZLIN' && isHomepage && <ZlinHeader />}
			<Box
				bg={inverted ? header.bgInverted : header.bg}
				position={university === 'ZLIN' && isHomepage ? 'sticky' : undefined}
				top={university === 'ZLIN' && isHomepage ? 0 : undefined}
				zIndex={university === 'ZLIN' && isHomepage ? 3 : undefined}
			>
				<ResponsivePadder
					id={HEADER_WRAPPER_ID}
					position={university === 'ZLIN' && isHomepage ? 'sticky' : undefined}
					top={university === 'ZLIN' && isHomepage ? 0 : undefined}
					as="header"
					bg={inverted ? header.bgInverted : header.bg}
					py={3}
					justifyContent="space-between"
					alignItems="center"
					css={(theme: Theme) => css`
						display: grid;
						grid-gap: ${theme.space[3]}px;
						width: 100%;
						justify-content: space-between;

						/* Collapsed */
						@media (max-width: ${theme.breakpoints[2]}) {
							grid-template-areas: 'logo notifications-menu side-menu';
							grid-template-columns: 1fr max-content max-content;
							justify-items: flex-start;
						}

						/* Expanded */
						@media (min-width: ${theme.breakpoints[2]}) {
							${user?.name
								? css`
										grid-template-areas: 'logo links notifications user';
										grid-template-columns: max-content 1fr max-content max-content;
								  `
								: css`
										grid-template-areas: 'logo links public-links';
										grid-template-columns: max-content 1fr max-content;
								  `}
						}
					`}
				>
					<OffscreenText as="h1">{title}</OffscreenText>

					<Box
						as={p => <Link to="/" {...p} />}
						gridArea="logo"
						px={2}
						aria-label={localize({ CZECH: 'domů', ENGLISH: 'homepage' })}
					>
						<Flex alignItems="center">
							{university === 'PLZEN' && (
								<Flex
									color={inverted ? 'white' : 'primary'}
									height={80}
									justifyContent="flex-start"
									alignItems="center"
									p={0}
									m={0}
									mr={30}
									width={140}
									css={(theme: Theme) => css`
										@media (max-width: ${theme.breakpoints[1]}) {
											width: 100px;
											height: 60px;
										}
										@media (max-width: ${theme.breakpoints[2]}) {
											width: 100px;
											margin-right: 10px;
											height: 60px;
										}
									`}
								>
									<UniLogoSvg />
								</Flex>
							)}
							{/* show other logo only after a login, inside of the job portal */}
							{/* show just logo symbol when on mobile */}
							{/* eslint-disable-next-line no-nested-ternary */}
							{university === 'ZLIN' ? (
								user && !isHomepage ? (
									<>
										<Box
											css={(theme: Theme) => css`
												@media (max-width: ${theme.breakpoints[1]}) {
													display: none;
												}
											`}
										>
											<img {...header.logo} />
										</Box>
										<Box
											css={(theme: Theme) => css`
												@media (min-width: ${theme.breakpoints[1]}) {
													display: none;
												}
											`}
										>
											<img {...header.logoMobile} />
										</Box>
									</>
								) : (
									<>
										<Box
											css={(theme: Theme) => css`
												@media (max-width: ${theme.breakpoints[1]}) {
													display: none;
												}
											`}
										>
											<img {...header.uniLogo} />
										</Box>
										<Box
											css={(theme: Theme) => css`
												@media (min-width: ${theme.breakpoints[1]}) {
													display: none;
												}
											`}
										>
											<img {...header.uniLogoMobile} />
										</Box>
									</>
								)
							) : university !== 'PLZEN' ? (
								<img
									{...header.logo}
									css={
										// eslint-disable-next-line no-nested-ternary
										inverted
											? (theme: Theme) => css`
													filter: brightness(0) invert(1);
													@media (max-width: ${theme.breakpoints[1]}) {
														height: ${university === 'MUNI' ? '50px' : 'auto'};
													}
											  `
											: // eslint-disable-next-line no-nested-ternary
											university === 'MUNI'
											? (theme: Theme) => css`
													@media (max-width: ${theme.breakpoints[1]}) {
														height: 50px;
													}
											  `
											: undefined //liberec
									}
								/>
							) : (
								//Plzen
								<Flex
									width={165}
									p={0}
									mt={-100}
									height={80}
									color={inverted ? 'white' : 'primary'}
									css={(theme: Theme) => css`
										@media (max-width: 320px) {
											display: none;
										}
										@media (max-width: ${theme.breakpoints[2]}) {
											width: 110px;
										}
									`}
								>
									<PortalLogoSvg />
								</Flex>
							)}
						</Flex>
					</Box>

					<Flex
						gridArea="links"
						flexWrap="wrap"
						justifyContent="flex-end"
						css={(theme: Theme) => css`
							text-transform: uppercase;
							@media (max-width: ${theme.breakpoints[2]}) {
								display: none;
							}
						`}
					>
						{items.map((item, index) => (
							<NavLink
								key={`header-nav-item-${localize(item.title)}-${index}`}
								to={item.to}
								p={3}
								target={item.target}
								color={linkColor}
								css={navLinkOrg}
							>
								{localize(item.title)}
							</NavLink>
						))}
					</Flex>

					<Box
						color="primary"
						gridArea="side-menu"
						display={['flex', 'flex', 'flex', 'none']}
					>
						<SideMenu
							user={user}
							badgeItems={badgeItems}
							items={items}
							color={linkColor}
						/>
					</Box>

					{user?.name ? (
						<Fragment>
							<Flex
								alignItems="center"
								flexShrink={0}
								gridArea="notifications"
								css={(theme: Theme) => css`
									@media (max-width: ${theme.breakpoints[2]}) {
										display: none;
									}
								`}
							>
								<NotificationsBadge />
							</Flex>

							<Flex
								alignItems="center"
								flexShrink={0}
								gridArea="notifications-menu"
								display={['flex', 'flex', 'flex', 'none']}
							>
								<SideNotificationsMenu />
							</Flex>

							<Flex
								alignItems="center"
								flexShrink={0}
								gridArea="user"
								css={(theme: Theme) => css`
									@media (max-width: ${theme.breakpoints[2]}) {
										display: none;
									}
								`}
							>
								<UserBadge user={user} badgeItems={badgeItems} />
								<LanguageSwitch linkColor={linkColor} />
							</Flex>
						</Fragment>
					) : (
						<Grid
							gridArea="public-links"
							gridGap={2}
							gridAutoFlow="column"
							gridAutoColumns="max-content"
							alignItems="center"
							justifyContent="flex-end"
							css={(theme: Theme) => css`
								text-transform: uppercase;
								@media (max-width: ${theme.breakpoints[2]}) {
									display: none;
								}
							`}
						>
							{!user && (
								<NavLink
									to="/login"
									p={3}
									color={linkColor}
									css={loginButtonOrg}
								>
									{t('login')}
								</NavLink>
							)}

							<LanguageSwitch linkColor={linkColor} />
						</Grid>
					)}
				</ResponsivePadder>
				{!isHomepage && <Divider {...header.divider} />}
			</Box>
		</>
	);
};

export default Header;
