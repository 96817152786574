import { useQuery } from 'react-query';

import { responseWithData } from 'utils';

import { EASParams } from 'utils/EASTypes';
import { PrefixFromPersonType } from 'utils/enumMaps';

import { Backend } from './endpoints';

import { api, authEndpoint, infiniteEndpoint } from '.';

// Student

export const useLoggedInStudent = authEndpoint<Backend.Student>(
	['student', 'logged-in'],
	api => api.get('students/logged-in'),
);

export const useStudent = authEndpoint<
	Backend.StudentDetail | Backend.StudentAdminDetail,
	[id: string]
>(['student'], (api, id) => api.get(`students/${id}/dto-by-id`));

// Students

export const useStudents = infiniteEndpoint<
	Backend.StudentDetail | Backend.StudentFindAllDetail,
	[json: EASParams]
>(['students'], (api, json) => api.post('students/dto', { json }));

// Education

export const useEducations = authEndpoint<Backend.Education[]>(
	['logged-in', 'education'],
	api => api.get('students/logged-in/education'),
);

export const registerStudent = (
	id: string,
	username: string,
	json: Backend.StudentUpdate,
) => api().put(`student/students/${id}/register/${username}`, { json });

// Company

export const useSearchStudents = authEndpoint<
	Backend.StudentResultDto[],
	[json: Backend.StudentSearchDto]
>(['students', 'search'], (api, json) => api.post(`students/search`, { json }));

export const revealStudent = (studentId: string, companyId: string) =>
	responseWithData(
		api().get(`company/students/${studentId}/reveal-for-company/${companyId}`),
		async r => r.ok && Boolean(await r.json()),
	);

export const useRevealedStudents = authEndpoint<Backend.Student[]>(
	['students', 'revealed'],
	api => api.get('students/revealed'),
);

export const useSuggestedStudents = authEndpoint<Backend.StudentResultDto[]>(
	['students', 'dashboard'],
	api => api.post('students/dashboard'),
);

// Edit

export const updateStudentProfile = (
	json: Backend.StudentUpdate,
	personType?: Backend.CommonPersonType,
) =>
	api(
		PrefixFromPersonType[personType ?? 'PUBLIC'],
	).put('students/update-profile', { json });

export const generateStudentCV = (
	id: string,
	university: Backend.University,
	personType: Backend.CommonPersonType = 'RECRUITER',
) =>
	api(PrefixFromPersonType[personType ?? 'PUBLIC'])
		.put(`students/${id}/generate/cv`, {
			searchParams: {
				reportType:
					personType === 'ADMIN' || personType === 'RECRUITER' ? 'PDF' : 'DOCX',
				university,
			},
		})
		.blob();

export const studentWithdrawGdpr = () =>
	api().put('student/students/withdraw-gdpr');

// Activation

export const studentsActivate = (studentIdentifier: string) =>
	api().put(`students/${studentIdentifier}/activate`);

export const studentsDeactivate = (studentIdentifier: string) =>
	api().put(`students/${studentIdentifier}/deactivate`);

// Admin

export const exportStudents = (params: EASParams) =>
	api()
		.post(`admin/students/export`, {
			searchParams: { reportType: 'CSV' },
			json: { ...params, size: -1 },
		})
		.blob();

// Follow

export const companiesFollow = (companyId: string) =>
	api().put(`student/companies/${companyId}/follow`);

export const companiesUnfollow = (companyId: string) =>
	api().delete(`student/companies/${companyId}/unfollow`);

// Suggested

export const useSuggestedOffersOfStudent = () =>
	useQuery('student/offers/recommended', () =>
		api().get('student/offers/recommended').json<Backend.OfferResultDto[]>(),
	);

export const useSuggesteCompaniesOfStudent = () =>
	useQuery('student/companies/recommended', () =>
		api().get('student/companies/recommended').json<Backend.CompanyDetail[]>(),
	);

// Education
// avoid to call the api when other university is built
export const useStudentMuniGDPR = () =>
	useQuery(
		'logged-in',
		() =>
			process.env.REACT_APP_UNIVERSITY !== 'MUNI'
				? true
				: api()
						.get('student/students/logged-in/education')
						.then(r => r.text())
						.then(r => (!r ? false : true))
						.catch(() => true),
		{
			staleTime: Infinity,
			retry: false,
		},
	);
