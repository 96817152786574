import { HeaderItem, UserBadgeItem } from 'components/header';

import { ProfileIcon, SettingsIcon } from 'assets';
import { JchUser } from 'api';

import { isAdminUser, isRecruiterUser, isStudentUser } from './token';

// Company
export const COMPANY_HEADER_ITEMS: HeaderItem[] = [
	{
		title: {
			ENGLISH: 'Search students',
			CZECH: 'Hledat studenty',
		},
		to: `/search/students`,
	},
	{
		title: {
			ENGLISH: 'My offers',
			CZECH: 'Moje nabídky',
		},
		to: `/offers`,
	},
	{
		title: {
			ENGLISH: 'My orders',
			CZECH: 'Moje objednávky',
		},
		to: `/orders`,
	},
];

const COMPANY_BADGE_ITEMS: UserBadgeItem[] = [
	{
		title: {
			ENGLISH: 'Profile',
			CZECH: 'Profil',
		},
		icon: <ProfileIcon width="100%" height="100%" />,
		to: '/company',
	},
	{
		title: {
			ENGLISH: 'Settings',
			CZECH: 'Nastavení',
		},
		icon: <SettingsIcon width="100%" height="100%" />,
		to: '/settings',
	},
];

// Student
export const STUDENT_HEADER_ITEMS: HeaderItem[] = [
	{
		title: {
			ENGLISH: 'Job offers',
			CZECH: 'Pracovní nabídky',
		},
		to: `/search/offers`,
	},
	{
		title: {
			ENGLISH: 'Companies',
			CZECH: 'Zaměstnavatelé',
		},
		to: `/search/companies`,
	},
];

const STUDENT_BADGE_ITEMS: UserBadgeItem[] = [
	{
		title: {
			ENGLISH: 'Profile',
			CZECH: 'Profil',
		},
		icon: <ProfileIcon width="100%" height="100%" />,
		to: '/student',
	},
	{
		title: {
			ENGLISH: 'Settings',
			CZECH: 'Nastavení',
		},
		icon: <SettingsIcon width="100%" height="100%" />,
		to: '/settings',
	},
];

// Admin
export const ADMIN_HEADER_ITEMS: HeaderItem[] = [
	{
		title: {
			ENGLISH: 'Job offers',
			CZECH: 'Pracovní nabídky',
		},
		to: `/search/offers`,
	},
	{
		title: {
			ENGLISH: 'Statistics',
			CZECH: 'Statistiky',
		},
		to: `/statistics`,
	},
	{
		title: {
			ENGLISH: 'Products',
			CZECH: 'Produkty',
		},
		to: `/products`,
	},
	{
		title: {
			ENGLISH: 'Companies',
			CZECH: 'Zaměstnavatelé',
		},
		to: `/search/companies`,
	},
	{
		title: {
			ENGLISH: 'Orders',
			CZECH: 'Objednávky',
		},
		to: `/orders`,
	},
	{
		title: {
			ENGLISH: 'Students',
			CZECH: 'Studenti',
		},
		to: `/search/students`,
	},
];

// Get header items based on user authentication
export const getUserHeaderItems = (user?: JchUser) => {
	if (isAdminUser(user)) {
		// Admin header items
		return {
			items: ADMIN_HEADER_ITEMS,
			badgeItems: [],
		};
	} else if (isStudentUser(user) && user?.name) {
		// Student header items
		return {
			items: STUDENT_HEADER_ITEMS,
			badgeItems: STUDENT_BADGE_ITEMS,
		};
	} else if (isRecruiterUser(user)) {
		// Company header items
		return {
			items: COMPANY_HEADER_ITEMS,
			badgeItems: COMPANY_BADGE_ITEMS,
		};
	}

	// Non auth
	return {
		items: [],
		badgeItems: [],
	};
};
