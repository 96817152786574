import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled/macro';

import { Box } from 'components/styled';

import { ReactComponent as Basket } from './basket.svg';
import { ReactComponent as Checkmark } from './checkmark.svg';
import { ReactComponent as Cross } from './cross.svg';
import { ReactComponent as Download } from './download.svg';
import { ReactComponent as Export } from './export.svg';
import { ReactComponent as Filter } from './filter.svg';
import { ReactComponent as LockClosed } from './lock-closed.svg';
import { ReactComponent as LockOpen } from './lock-open.svg';
import { ReactComponent as NotificationsEmpty } from './notifications-empty.svg';
import { ReactComponent as Notifications } from './notifications.svg';
import { ReactComponent as Offer } from './offer.svg';
import { ReactComponent as Package } from './package.svg';
import { ReactComponent as ProfileI } from './profile-i.svg';
import { ReactComponent as Profile } from './profile.svg';
import { ReactComponent as Reveals } from './reveals.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as Settings } from './settings.svg';
import { ReactComponent as Upload } from './upload.svg';
import { ReactComponent as License } from './license.svg';
import { ReactComponent as Person } from './person.svg';
import { ReactComponent as Driver } from './driver.svg';
import { ReactComponent as Graduate } from './graduate.svg';
import { ReactComponent as Piggy } from './piggy.svg';

const Icon = styled(Box, { shouldForwardProp: isPropValid })``;

export const BasketIcon = Icon.withComponent(Basket);
export const CheckmarkIcon = Icon.withComponent(Checkmark);
export const CrossIcon = Icon.withComponent(Cross);
export const DownloadIcon = Icon.withComponent(Download);
export const ExportIcon = Icon.withComponent(Export);
export const FilterIcon = Icon.withComponent(Filter);
export const LockClosedIcon = Icon.withComponent(LockClosed);
export const LockOpenIcon = Icon.withComponent(LockOpen);
export const NotificationsEmptyIcon = Icon.withComponent(NotificationsEmpty);
export const NotificationsIcon = Icon.withComponent(Notifications);
export const OfferIcon = Icon.withComponent(Offer);
export const PackageIcon = Icon.withComponent(Package);
export const ProfileIcon = Icon.withComponent(Profile);
export const ProfileIconI = Icon.withComponent(ProfileI);
export const RevealsIcon = Icon.withComponent(Reveals);
export const SearchIcon = Icon.withComponent(Search);
export const SettingsIcon = Icon.withComponent(Settings);
export const UploadIcon = Icon.withComponent(Upload);
export const LicenseIcon = Icon.withComponent(License);
export const PersonIcon = Icon.withComponent(Person);
export const DriverIcon = Icon.withComponent(Driver);
export const GraduateIcon = Icon.withComponent(Graduate);
export const PiggyIcon = Icon.withComponent(Piggy);
