/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { forwardRef } from 'react';
import {
	color,
	layout,
	LayoutProps,
	typography,
	TypographyProps,
	space,
	SpaceProps,
	ColorProps,
	position,
	PositionProps,
} from 'styled-system';
import styled from '@emotion/styled/macro';

import LoaderSpin from 'components/loaders/LoaderSpin';
import Link, { HrefLink } from 'components/styled/Link';
import { Box } from 'components/styled';

import { InvertFocusStyle } from 'theme';

type ButtonProps = {
	variant?: 'default' | 'primary' | 'outlined' | 'error' | 'text';
	sizeType?: 'normal' | 'big';
	invertFocus?: boolean;
};

type Props = LayoutProps &
	SpaceProps &
	TypographyProps &
	ButtonProps &
	ColorProps &
	PositionProps;

export const resetButtonStyle = css`
	line-height: 1.5;
	background: transparent;
	border: 0;
	cursor: pointer;
`;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const StyledButton = styled.button<Props>`
	${resetButtonStyle}

	background: ${p => p.theme.colors.secondary};
	padding: ${p => p.theme.space[3]}px;
	font-size: ${p => p.theme.fontSizes.md}px;
	text-align: center;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	${p =>
		p.variant === 'default' &&
		css`
			&:hover {
				border-color: white;
				background: white;
				color: ${p.theme.colors.primary};
			}
		`}

	&:disabled {
		cursor: not-allowed;
		${p =>
			p.variant !== 'text' &&
			css`
				background: ${p.theme.colors.textLight};
			`}

		${p =>
			p.variant === 'text' &&
			css`
				color: ${p.theme.colors.textLight};
			`}
	}

	${p =>
		p.variant !== 'text' &&
		css`
			color: white;
			min-width: 100px;
		`}

	${p =>
		p.variant === 'primary' &&
		css`
			background: ${p.theme.colors.primary};
			border: 1px solid transparent;
			&:hover {
				border-color: ${p.theme.colors.primary};
				background: white;
				color: ${p.theme.colors.primary};
			}
		`}

  	${p =>
		p.variant === 'outlined' &&
		css`
			background: transparent;
			border: 1px solid ${p.theme.colors.primary};
			color: ${p.theme.colors.primary};
			&:hover {
				text-decoration: underline;
			}
		`}

	${p =>
		p.variant === 'error' &&
		css`
			background: ${p.theme.colors.error};
			border: 1px solid transparent;
			&:hover {
				border-color: ${p.theme.colors.error};
				background: white;
				color: ${p.theme.colors.error};
			}
		`}

  	${p =>
		p.sizeType === 'big' &&
		css`
			font-size: ${p.theme.fontSizes.xl}px;
			padding-left: ${p.theme.space[4]}px;
			padding-right: ${p.theme.space[4]}px;

			@media screen and (max-width: ${p.theme.breakpoints[0]}) {
				font-size: ${p.theme.fontSizes.lg}px;
				padding: ${p.theme.space[2]}px ${p.theme.space[3]}px;
			}
		`}

		${p =>
		p.variant === 'text' &&
		css`
			${!p.color && `color: ${p.theme.colors.secondary};`}
			background: transparent;
			border: 0;
		`}

		${p =>
		p.invertFocus &&
		css`
			&:focus {
				${InvertFocusStyle}
			}
		`}

    ${layout}
    ${space}
    ${typography}
		${color}
		${position}
`;

type StyledButtonProps = React.ComponentProps<typeof StyledButton>;

StyledButton.defaultProps = {
	type: 'button',
	variant: 'default',
	sizeType: 'normal',
};

const Button = forwardRef<
	HTMLButtonElement,
	StyledButtonProps & { loading?: boolean }
>(({ children, loading, ...buttonProps }, ref) => (
	<StyledButton {...buttonProps} ref={ref}>
		{loading && (
			<Box display="inline-block" mr={2}>
				<LoaderSpin
					color="white"
					size={buttonProps.sizeType === 'big' ? 25 : 18}
				/>
			</Box>
		)}
		{children}
	</StyledButton>
));

Button.displayName = Button.name;

export const NavButton = styled(StyledButton)`
	cursor: pointer;
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
	${p =>
		p.variant === 'outlined' &&
		css`
			&:hover {
				text-decoration: underline;
			}
		`}
`;

export const NavLinkButton = NavButton.withComponent(Link);
export const NavHrefButton = NavButton.withComponent(HrefLink);

export default Button;
