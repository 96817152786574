import { formFilterColorVariantType, PartialTheme } from 'theme';

import PortalLogo from 'assets/liberec/portal-logo.png';
import { ReactComponent as RegistrationImage } from 'assets/liberec/registration-image.svg';

const theme: PartialTheme = {
	university: 'LIBEREC',
	title: 'JobTUL',
	colors: {
		primary: '#7B204F',
		secondary: '#747476',
		darkerGrey: '#D4D5D6',
		lightGrey: '#FAFAFA',
		text: '#000',
		chipBorder: '#D4D5D6',
	},
	fontFamily: 'Myriad Pro',
	header: {
		shortLanguages: true,
		logo: { src: PortalLogo, alt: 'JobTUL', height: 40 },
		bg: 'darkerGrey',
		bgInverted: 'darkerGrey',
		publicHeaderItems: {
			items: [
				{
					title: {
						ENGLISH: 'About Career Centre TUL',
						CZECH: 'O Kariérovém Centru TUL',
					},
					to: '/public/o-karierovem-centru',
				},
				{
					title: {
						ENGLISH: 'For students',
						CZECH: 'Pro studenty',
					},
					to: '/public/pro-studenty',
				},
				{
					title: {
						ENGLISH: 'For companies',
						CZECH: 'Pro zaměstnavatele',
					},
					to: '/public/pro-zamestnavatele',
				},
			],
			badgeItems: [],
		},
	},
	RegistrationImage: RegistrationImage,
	formFilterColorVariant: 'inverted' as formFilterColorVariantType,
};

export default theme;
