/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import styled from '@emotion/styled/macro';
import * as CSS from 'csstype';

import { Box, BoxProps } from 'components/styled';

import { OffscreenCSS } from 'theme/GlobalStyles';

type Props = {
	ellipsis?: boolean;
	whiteSpace?: CSS.Property.WhiteSpace;
};

const Text = styled(Box)<BoxProps & Props>`
	${p =>
		p.ellipsis &&
		css`
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		`}
	${p => p.whiteSpace && `white-space: ${p.whiteSpace}`}
`.withComponent('p');

export const OffscreenText = styled(Text)`
	${OffscreenCSS}
`.withComponent('span');

type EmojiProps = {
	label: string;
	symbol: string;
};

export const Emoji = ({ label, symbol }: EmojiProps) => (
	<Text as="span" aria-label={label} role="img" fontStyle="normal">
		{symbol}
	</Text>
);

export default Text;
