import React, { FC } from 'react';
import { useTheme } from '@emotion/react';
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

import { Box, Flex } from 'components/styled';
import Text from 'components/styled/Text';
import { ResponsivePadder } from 'components/styled/Wrapper';
import { NavHrefButton } from 'components/styled/Button';
import { HrefLink } from 'components/styled/Link';

import CCLogo from 'assets/plzen/career-center-logo.png';

const PlzenFooter: FC = () => {
	const { careerCentreUrl } = useTheme();

	return (
		<Flex as="footer" flexDirection="column">
			<ResponsivePadder
				flexGrow={1}
				alignItems="center"
				flexDirection={['column', 'row']}
				justifyContent={['center', 'space-between']}
				my={3}
			>
				<HrefLink href={careerCentreUrl} target="_blank">
					<Box>
						<img
							src={CCLogo}
							alt="Karierní centrum Západočeské Univerzity v Plzni"
						/>
					</Box>
				</HrefLink>
				<Box color="primary">
					<NavHrefButton
						aria-label="facebook"
						href="https://www.facebook.com/unijobsZCU"
						target="_blank"
						variant="text"
						p={2}
						mr={2}
						minWidth={0}
						size={41}
						color="primary"
					>
						<FaFacebookF />
					</NavHrefButton>
					<NavHrefButton
						aria-label="instagram"
						href="https://www.instagram.com/careerzcu/"
						target="_blank"
						variant="text"
						p={2}
						mr={2}
						minWidth={0}
						size={41}
						color="primary"
					>
						<FaInstagram />
					</NavHrefButton>
					<NavHrefButton
						aria-label="linkedin"
						href="https://www.linkedin.com/school/z%C3%A1pado%C4%8Desk%C3%A1-univerzita-v-plzni-z%C4%8Du-/?originalSubdomain=cz"
						target="_blank"
						variant="text"
						p={2}
						minWidth={0}
						size={41}
						color="primary"
					>
						<FaLinkedinIn />
					</NavHrefButton>
				</Box>
			</ResponsivePadder>
			<Box bg="primary">
				<ResponsivePadder
					flexDirection={['column', 'row']}
					justifyContent={['center', 'space-between']}
					textAlign={['center', 'left']}
					color="white"
					my={2}
				>
					<Text>© ZČU 1991—2021</Text>
					<Text>Created by InQool a.s.</Text>
				</ResponsivePadder>
			</Box>
		</Flex>
	);
};

export default PlzenFooter;
