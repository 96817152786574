import { useTheme } from '@emotion/react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/styled/Button';

type Props = {
	linkColor?: string;
};

const LanguageSwitch: FC<Props> = ({ linkColor }) => {
	const { i18n } = useTranslation();
	const { header } = useTheme();

	// eslint-disable-next-line no-nested-ternary
	return header.shortLanguages ? (
		<>
			<Button
				aria-label="čeština"
				variant="text"
				color={linkColor}
				onClick={() => i18n.changeLanguage('cz')}
				fontWeight={i18n.language === 'cz' ? 'bold' : undefined}
			>
				CZ
			</Button>
			/
			<Button
				aria-label="english"
				variant="text"
				color={linkColor}
				onClick={() => i18n.changeLanguage('en')}
				fontWeight={i18n.language === 'en' ? 'bold' : undefined}
			>
				EN
			</Button>
		</>
	) : i18n.language === 'en' ? (
		<Button
			aria-label="čeština"
			variant="text"
			color={linkColor}
			onClick={() => i18n.changeLanguage('cz')}
		>
			Česky
		</Button>
	) : (
		<Button
			aria-label="english"
			variant="text"
			color={linkColor}
			onClick={() => i18n.changeLanguage('en')}
		>
			English
		</Button>
	);
};
export default LanguageSwitch;
