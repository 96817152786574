import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { setLocale } from 'yup';

import { LocalizationToLanguage } from './enumMaps';
import { EASMultilanguage } from './EASTypes';

// Helper to flatten localizations object
const flatten = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	obj: Record<string, any>,
	ns = '',
): Record<string, Record<string, string>> => {
	// If it's not an object
	if (typeof obj !== 'object') {
		return {};
	}

	// If depth is one
	if (Object.values(obj).every(k => typeof k === 'string')) {
		return { [ns]: obj };
	}

	return Object.entries(obj).reduce(
		(acc, cur) => ({ ...acc, ...flatten(cur[1], `${ns}${cur[0]}/`) }),
		{} as Record<string, Record<string, string>>,
	);
};

export const useErrorTranslation = () => {
	const { t } = useTranslation('yup/');
	return (arg: string | { args: [string, never] }) =>
		// eslint-disable-next-line no-nested-ternary
		typeof arg === 'string'
			? t(arg)
			: Array.isArray(arg.args)
			? t(...arg.args)
			: '';
};

export const useLocalizedMultistring = () => {
	const { i18n } = useTranslation();
	const currentLanguage = LocalizationToLanguage[i18n.language];
	//TODO: implement fallback for more languages
	const otherLanguage = currentLanguage === 'CZECH' ? 'ENGLISH' : 'CZECH';

	return useCallback(
		(value?: EASMultilanguage, substitute?: Record<string, string>) => {
			if (value?.[currentLanguage] && value?.[currentLanguage] !== '') {
				let str = value?.[currentLanguage] ?? '';
				if (!substitute) {
					return str;
				}
				for (const key in substitute) {
					str = str.replaceAll(`{{${key}}}`, substitute?.[key] ?? '');
				}
				return str;
			}

			if (value?.[otherLanguage] && value?.[otherLanguage] !== '') {
				let str = value?.[otherLanguage] ?? '';
				if (!substitute) {
					return str;
				}
				for (const key in substitute) {
					str = str.replaceAll(`{{${key}}}`, substitute?.[key] ?? '');
				}
				return str;
			}
			return '';
		},
		[currentLanguage, otherLanguage],
	);
};

// Initialize i18n
i18n.use(initReactI18next).init({
	resources: {},
	lng: 'cz',
	fallbackLng: 'cz',

	keySeparator: false,
	interpolation: {
		escapeValue: false,
	},
});

// Get locales resources from the public folder and add them to i18n
const getLocales = async () => {
	const isZLIN = process.env.REACT_APP_UNIVERSITY === 'ZLIN';
	const czRes = await fetch(window.location.origin + '/locales/cz.json');
	const enRes = await fetch(window.location.origin + '/locales/en.json');
	const czHpRes = isZLIN
		? await fetch(window.location.origin + '/locales/cz_hp.json')
		: ((null as unknown) as Response);
	const enHpRes = isZLIN
		? await fetch(window.location.origin + '/locales/en_hp.json')
		: ((null as unknown) as Response);
	return [
		await czRes.json(),
		await enRes.json(),
		isZLIN ? await czHpRes.json() : null,
		isZLIN ? await enHpRes.json() : null,
	];
};

getLocales().then(data => {
	const [cz, en, czHp, enHp] = data;
	Object.entries(flatten(cz)).forEach(([ns, val]) =>
		i18n.addResourceBundle('cz', ns, val),
	);
	czHp &&
		Object.entries(flatten(czHp)).forEach(([ns, val]) =>
			i18n.addResourceBundle('cz', ns, val),
		);

	Object.entries(flatten(en)).forEach(([ns, val]) =>
		i18n.addResourceBundle('en', ns, val),
	);

	enHp &&
		Object.entries(flatten(enHp)).forEach(([ns, val]) =>
			i18n.addResourceBundle('en', ns, val),
		);
	// rerender whole page after all jsons are loaded to prevent missing translations
	i18n.changeLanguage('cz');
});

// Change default yup error messages to `Localizations['yup']` keys
setLocale({
	mixed: {
		required: 'required',
		notType: 'not_type',
	},
	string: {
		email: 'email',
		url: 'url',
		max: ({ max }) => ({
			args: ['max_string', { max }],
		}),
	},
	number: {
		max: ({ max }) => ({
			args: ['max_number', { max }],
		}),
	},
});

export default i18n;
