import { PrefixFromPersonType } from 'utils/enumMaps';
import { EASResult } from 'utils/EASTypes';

import { Backend } from './endpoints';

import { api, authEndpoint, infiniteEndpoint } from '.';

export const useNotificationsAll = infiniteEndpoint<Backend.Notification>(
	['notifications'],
	api =>
		api.post('notifications/received', {
			json: {
				sort: [{ field: 'created', order: 'DESC' }],
				size: 10,
			},
		}),
);

export const useNotificationsNewest = authEndpoint<
	EASResult<Backend.Notification>
>(['notifications', 'newest'], api =>
	api.post('notifications/received', {
		json: {
			sort: [
				{ field: 'read', order: 'ASC' },
				{ field: 'created', order: 'DESC' },
			],
			size: 3,
		},
	}),
);

export const useNotificationUnreadCount = authEndpoint<number>(
	['notifications', 'count'],
	api => api.get('notifications/received/count'),
);

export const markNotificationRead = (
	id: string,
	personType?: Backend.CommonPersonType,
) =>
	api(PrefixFromPersonType[personType ?? 'PUBLIC']).post(
		`notifications/${id}/read`,
	);

export const markAllNotificationRead = (
	personType?: Backend.CommonPersonType,
) =>
	api(PrefixFromPersonType[personType ?? 'PUBLIC']).post(
		'notifications/read/all',
	);
