import { ResponsePromise } from 'ky';
import normalizeUtility from 'normalize-url';

import { LocaleFromLocalization, LangTagFromLocalization } from './enumMaps';

export type DeepPartial<T> = {
	[P in keyof T]?: DeepPartial<T[P]>;
};

export type FilteredKeys<T, C> = {
	[K in keyof T]: T[K] extends C ? K : never;
}[keyof T];

export const getDateString = (date: Date, loc: string) =>
	(typeof date === 'string' ? new Date(date) : date)?.toLocaleDateString(
		LocaleFromLocalization[loc],
	);

export const getMonthYearString = (date: Date, loc: string) => {
	const d = typeof date === 'string' ? new Date(date) : date;
	const ye = new Intl.DateTimeFormat(LocaleFromLocalization[loc], {
		year: 'numeric',
	}).format(d);
	const mo = new Intl.DateTimeFormat(LocaleFromLocalization[loc], {
		month: 'long',
	}).format(d);
	return `${mo} ${ye}`;
};

export const getTimeString = (date: Date, loc: string) =>
	(typeof date === 'string' ? new Date(date) : date)?.toLocaleTimeString(
		LocaleFromLocalization[loc],
		{
			hour: '2-digit',
			minute: '2-digit',
		},
	);

export const getFullName = ({
	firstName,
	lastName,
	titleBefore,
	titleAfter,
}: Record<'firstName' | 'lastName' | 'titleBefore' | 'titleAfter', string>) =>
	[titleBefore, firstName, lastName, titleAfter].join(' ').trim();

export function assert(
	condition: boolean | undefined,
	msg?: string,
): asserts condition {
	if (!condition) {
		throw new Error(msg);
	}
}

export const pluralRules = (
	count: number,
	options: { one: string; few: string; many: string },
	showCount = true,
) => {
	switch (Math.abs(count)) {
		case 1:
			return showCount ? `${count} ${options.one}` : options.one;
		case 2:
		case 3:
		case 4:
			return showCount ? `${count} ${options.few}` : options.few;
		default:
			return showCount ? `${count} ${options.many}` : options.many;
	}
};

export const responseWithData = <T>(
	response: ResponsePromise,
	getData: (r: Response) => Promise<T> | T = async r => (await r.json()) as T,
) => response.then(async r => [r, await getData(r)] as const);

export const formatCzechCrowns = (price: number, tag?: string) =>
	new Intl.NumberFormat(LangTagFromLocalization[tag ?? 'cz'], {
		style: 'currency',
		currency: 'CZK',
		minimumFractionDigits: 0,
	}).format(price);

export const normalizeUrl = (url: string) => {
	try {
		return url ? normalizeUtility(url, { stripWWW: false }) : '';
	} catch (error) {
		return '';
	}
};

export const asRecord = <T extends unknown>(...obj: T[]): Record<number, T> =>
	obj;

export const retryPromise = <T>(
	fn: () => Promise<T>,
	retriesLeft = 4,
	interval = 250,
) => {
	return new Promise<T>((resolve, reject) => {
		return fn()
			.then(resolve)
			.catch(error => {
				if (retriesLeft === 1) {
					reject(error);
					return;
				}

				setTimeout(() => {
					// Passing on "reject" is the important part
					retryPromise(fn, retriesLeft - 1, interval).then(resolve, reject);
				}, interval);
			});
	});
};

export const areEqualProps = <T extends Record<string, unknown>>(
	keys: (keyof T)[],
) => (lhs: T, rhs: T) => keys.every(key => lhs[key] === rhs[key]);

export const asyncForEach = async <T>(
	array: T[],
	callback: (array: T, index: number, arr: T[]) => Promise<unknown>,
) => {
	for (let index = 0; index < array.length; index++) {
		await callback(array[index], index, array);
	}
};
