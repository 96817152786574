/** @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import '@reach/dialog/styles.css';
import { FC, Fragment, useCallback, useState } from 'react';
import Dialog from '@reach/dialog';
import { MdCheck, MdKeyboardArrowDown } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { useQueryCache } from 'react-query';

import { Box, Flex } from 'components/styled';
import Button, { resetButtonStyle } from 'components/styled/Button';
import Divider from 'components/styled/Divider';
import NotificationItem from 'components/tiles/NotificationItem';
import Text from 'components/styled/Text';
import { NavLink } from 'components/styled/Link';
import LoaderBounce from 'components/loaders/LoaderBounce';
import ErrorScreen from 'components/error/ErrorScreen';

import { CrossIcon, NotificationsIcon } from 'assets';
import { useLoggedInUser } from 'api';

import {
	markAllNotificationRead,
	useNotificationsNewest,
	useNotificationUnreadCount,
} from 'api/notificationsApi';

import { MenuAvatar } from './styled';

const ListItemCss = (theme: Theme) => css`
	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
		background-color: ${theme.colors.primaryLight};
	}

	&.active-route {
		font-weight: bold;
		background-color: ${theme.colors.lightGrey};

		&::after {
			content: '';
			position: absolute;
			right: 0;
			width: 5px;
			height: 100%;
			background-color: ${theme.colors.primary};
		}
	}
`;

const SideNotificationsMenu: FC = () => {
	const { t } = useTranslation('components/header/side_notifications_menu/');

	const [open, setOpen] = useState(false);
	const openMenu = useCallback(() => !open && setOpen(true), [open, setOpen]);
	const closeMenu = useCallback(() => open && setOpen(false), [open, setOpen]);

	const response = useNotificationsNewest();
	const countResponse = useNotificationUnreadCount();

	const user = useLoggedInUser();
	const cache = useQueryCache();

	const items = response.data?.items ?? [];
	const unreadCount = countResponse.data ?? 0;

	return (
		<Fragment>
			<MenuAvatar
				as="button"
				aria-label={t('user_notifications_aria')}
				disabled={items.length <= 0}
				onClick={openMenu}
				position="relative"
			>
				{unreadCount > 0 && (
					<Flex
						justifyContent="center"
						alignItems="center"
						size={14}
						bg="error"
						color="white"
						css={css`
							position: absolute;
							top: 4px;
							right: 4px;
							border-radius: 50%;
							font-size: 11px;
						`}
					>
						{unreadCount > 99 ? '*' : unreadCount}
					</Flex>
				)}
				<NotificationsIcon />
			</MenuAvatar>
			<Dialog
				isOpen={open}
				onDismiss={closeMenu}
				aria-label={t('side_menu_aria')}
				css={css`
					position: fixed;
					top: 0;
					right: 0;
					bottom: 0;
					background-color: white;
					padding: 0 !important;
					margin: 0 !important;
					width: auto;
					max-width: 400px;
					min-width: 300px;

					display: flex;
					flex-direction: column;
				`}
			>
				<Flex pl={3} pr={5} alignItems="center" height={82}>
					<Flex
						as="button"
						alignItems="center"
						justifyContent="center"
						onClick={closeMenu}
						size={28}
						css={css`
							${resetButtonStyle}
							border-radius: 50%;
						`}
					>
						<CrossIcon size={18} />
					</Flex>
					<Box flexGrow={1}>
						<Text ml={3} fontWeight="bold" fontSize="xl">
							{t('notifications')}
						</Text>
					</Box>
				</Flex>
				{unreadCount > 0 && (
					<Button
						onClick={async () => {
							await markAllNotificationRead(user?.personType);
							cache.invalidateQueries('notifications');
						}}
						fontWeight="bold"
						variant="text"
						color="primary"
						css={ListItemCss}
					>
						<Text p={0} m={0} mr={2}>
							{t('all_read')}
						</Text>
						<MdCheck size={20} />
					</Button>
				)}
				<Divider mx={5} my={2} color="darkerGrey" />
				{response.isError && <ErrorScreen {...response} />}
				{response.isLoading && (
					<Flex my={4} justifyContent="center">
						<LoaderBounce />
					</Flex>
				)}
				{items.map(item => (
					<Box key={item.id} pl={3} pr={5} py={3} css={ListItemCss}>
						<NotificationItem
							{...item}
							afterClick={closeMenu}
							variant="header"
						/>
					</Box>
				))}
				<NavLink to="/notifications" css={ListItemCss} onClick={closeMenu}>
					<Flex
						justifyContent="center"
						alignItems="center"
						color="primary"
						py={2}
						width="100%"
					>
						<Text mr={1} fontWeight="bold">
							{t('show_all')}
						</Text>
						<MdKeyboardArrowDown size={20} />
					</Flex>
				</NavLink>
			</Dialog>
		</Fragment>
	);
};
export default SideNotificationsMenu;
