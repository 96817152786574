import React, { FC } from 'react';
import { useTheme } from '@emotion/react';

import MuniFooter from './MuniFooter';
import LiberecFooter from './LiberecFooter';
import PlzenFooter from './PlzenFooter';
import ZlinFooter from './ZlinFooter';

const Footer: FC = () => {
	const currentUni = useTheme().university;
	switch (currentUni) {
		case 'MUNI':
			return <MuniFooter />;
		case 'PLZEN':
			return <PlzenFooter />;
		case 'LIBEREC':
			return <LiberecFooter />;
		case 'ZLIN':
			return <ZlinFooter />;
	}
};

export default Footer;
