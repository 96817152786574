/** @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import '@reach/dialog/styles.css';
import { FC, Fragment, useCallback, useState } from 'react';
import Dialog from '@reach/dialog';
import { MdMenu } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

import { Box, Flex } from 'components/styled';
import Text from 'components/styled/Text';
import Divider from 'components/styled/Divider';
import { resetButtonStyle } from 'components/styled/Button';
import { NavLink } from 'components/styled/Link';

import { CrossIcon, ProfileIconI } from 'assets';
import { JchUser } from 'api';

import { ReactComponent as LogoutIcon } from 'assets/logout-icon.svg';

import useLogout from 'hooks/useLogout';

import { useLocalizedMultistring } from 'utils/localization';

import { MenuAvatar } from './styled';
import LanguageSwitch from './LanguageSwitch';

import { HeaderItem, UserBadgeItem } from '.';

const ListItemCss = (theme: Theme) => css`
	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
		background-color: ${theme.colors.primaryLight};
	}

	&.active-route {
		font-weight: bold;
		background-color: ${theme.colors.lightGrey};

		&::after {
			content: '';
			position: absolute;
			right: 0;
			width: 5px;
			height: 100%;
			background-color: ${theme.colors.primary};
		}
	}
`;

type Props = {
	user?: JchUser;
	items: HeaderItem[];
	badgeItems: UserBadgeItem[];
	color: string;
};

const UserBadge: FC<Props['user']> = ({ photoId, name }) => (
	<Fragment>
		<MenuAvatar
			border
			css={css`
				cursor: default;
				:hover {
					background-color: transparent;
				}
			`}
		>
			{photoId && (
				<Box
					size="100%"
					css={css`
						background-image: url('/api/jch/files/${photoId}');
						background-position: center;
						background-size: cover;
					`}
				/>
			)}
			{!photoId && <ProfileIconI size={20} />}
		</MenuAvatar>
		<Box flexGrow={1}>
			<Text ml={2}>{name}</Text>
		</Box>
	</Fragment>
);

const SideMenu: FC<Props> = ({ user, items, badgeItems, color }) => {
	const localize = useLocalizedMultistring();
	const { t } = useTranslation('components/header/index/');

	const [open, setOpen] = useState(false);
	const openMenu = useCallback(() => !open && setOpen(true), [open, setOpen]);
	const closeMenu = useCallback(() => open && setOpen(false), [open, setOpen]);

	// Logout
	const handleLogOut = useLogout();
	return (
		<Fragment>
			<Box as="button" color={color} onClick={openMenu} css={resetButtonStyle}>
				<MdMenu size={24} />
			</Box>
			<Dialog
				isOpen={open}
				onDismiss={closeMenu}
				aria-label={t('side_menu')}
				css={css`
					position: fixed;
					top: 0;
					right: 0;
					bottom: 0;
					background-color: white;
					padding: 0 !important;
					margin: 0 !important;
					width: auto;
					max-width: 400px;
					min-width: 300px;

					display: flex;
					flex-direction: column;
					overflow-y: auto;
				`}
			>
				<Flex pl={4} pr={3} alignItems="center" height={82} flexShrink={0}>
					{user?.name && <UserBadge {...user} />}
					<Flex
						as="button"
						alignItems="center"
						justifyContent="center"
						onClick={closeMenu}
						size={28}
						mx={3}
						css={css`
							${resetButtonStyle}
							border-radius: 50%;
						`}
					>
						<CrossIcon size={18} />
					</Flex>
				</Flex>
				<Divider mx={4} my={2} color="darkerGrey" />
				{items.map(item => (
					<NavLink
						key={localize(item.title)}
						to={item.to}
						onClick={closeMenu}
						css={ListItemCss}
						target={item.target}
					>
						<Text pl={4} py={3} flexGrow={1}>
							{localize(item.title)}
						</Text>
					</NavLink>
				))}
				{user?.name && <Divider mx={4} my={2} color="darkerGrey" />}
				{badgeItems.map(item => (
					<NavLink
						key={localize(item.title)}
						to={item.to}
						onClick={closeMenu}
						css={ListItemCss}
					>
						<Text pl={4} py={3} flexGrow={1}>
							{localize(item.title)}
						</Text>
						<Box mx={4} width={18}>
							{item.icon}
						</Box>
					</NavLink>
				))}
				{user?.name && (
					<button
						onClick={handleLogOut}
						css={(theme: Theme) => css`
							${ListItemCss(theme)}
							${resetButtonStyle}
	            			padding: initial;
							font-size: initial;
							text-align: initial;
						`}
					>
						<Text pl={4} py={3} flexGrow={1}>
							{t('log_out')}
						</Text>
						<Box mx={4} width={18} height="100%">
							<LogoutIcon width="100%" height="100%" />
						</Box>
					</button>
				)}
				{!user && (
					<NavLink to="/login" onClick={closeMenu} css={ListItemCss}>
						<Text pl={4} py={3} flexGrow={1}>
							{t('login')}
						</Text>
					</NavLink>
				)}
				{(!user || user.name) && <Divider mx={4} my={2} color="darkerGrey" />}
				{/* <Flex justifyContent="center" alignItems="center" mt={4}> */}
				<LanguageSwitch linkColor="text" />
				{/* </Flex> */}
			</Dialog>
		</Fragment>
	);
};
export default SideMenu;
