import { useTheme } from '@emotion/react';
import { useCallback } from 'react';

import { useLoggedInUser } from 'api';

const useLogout = () => {
	const { university } = useTheme();
	const user = useLoggedInUser();
	return useCallback(async () => {
		if (
			university !== 'MUNI' &&
			user?.personType !== 'RECRUITER' &&
			user?.personType !== 'ALUMNUS'
		) {
			window.location.replace('/api/jch/public/logout');
		} else {
			window.location.replace('/api/jch/logout');
		}
	}, [university, user?.personType]);
};

export default useLogout;
