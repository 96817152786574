import decodeJWT from 'jwt-decode';
import isBefore from 'date-fns/isBefore';

import { JchUser } from 'api';

import { Backend } from 'api/endpoints';

type CommonProps = {
	sub: string;
	id: string;
	iat: number;
	exp: number;
	tutorialCompleted?: boolean;
};

export type AdminUser = CommonProps & {
	personType: Exclude<
		Backend.CommonPersonType,
		'RECRUITER' | 'STUDENT' | 'ALUMNUS'
	>;
};

export type StudentUser = CommonProps & {
	personType: Extract<Backend.CommonPersonType, 'STUDENT' | 'ALUMNUS'>;
	gdpr?: boolean | null;
};

export type RecruiterUser = CommonProps & {
	personType: Extract<Backend.CommonPersonType, 'RECRUITER'>;
	active: boolean;
	companyId: string;
};

export type ApiUser = AdminUser | StudentUser | RecruiterUser;

export const isAdminUser = (
	user?: Pick<JchUser, 'personType'>,
): user is AdminUser => user?.personType === 'ADMIN';

export const isStudentUser = (
	user?: Pick<JchUser, 'personType'>,
): user is StudentUser =>
	user?.personType === 'STUDENT' || user?.personType === 'ALUMNUS';

export const isRecruiterUser = (
	user?: Pick<JchUser, 'personType'>,
): user is RecruiterUser => user?.personType === 'RECRUITER';

export const decodeToken = (token: string) => {
	let decodedToken;
	// Decode token
	try {
		decodedToken = decodeJWT(token) as ApiUser;
	} catch (error) {
		decodedToken = null;
	}

	return decodedToken;
};

export const validateTokenExp = (user: ApiUser | null) => {
	if (user !== null && typeof user.exp === 'number') {
		// If exp date is before now, its invalid
		return isBefore(new Date(), new Date(user.exp * 1000));
	}
	// If exp date is not a number, its invalid
	return false;
};
