import { formFilterColorVariantType, PartialTheme } from 'theme';

import { ReactComponent as UniLogoSvg } from 'assets/plzen/uni-logo.svg';
import { ReactComponent as PortalLogoSVG } from 'assets/plzen/portal-logo.svg';
import { ReactComponent as RegistrationImage } from 'assets/plzen/registration-image.svg';

const theme: PartialTheme = {
	university: 'PLZEN',
	title: 'UniJobs - ZČU',
	colors: {
		primary: '#005CAB',
		primaryLight: '#F2F5FA',
		secondary: '#005CAB',
		darkerGrey: '#EDEDED',
		chipBackground: '#F2F5FA',
		chipBorder: '#B4B4B4',
	},
	fontFamily: 'Roboto Condensed',
	header: {
		shortLanguages: true,
		PortalLogoSvg: PortalLogoSVG,
		UniLogoSvg: UniLogoSvg,
		linkColor: 'primary',
		bg: 'white',
		bgInverted: 'primary',
		divider: { color: 'primary', size: 3 },
		publicHeaderItems: {
			items: [
				{
					title: {
						ENGLISH: 'What is UNIJobs',
						CZECH: 'Co je to UNIJobs',
					},
					to: '/public/about',
				},
				{
					title: {
						ENGLISH: 'For students',
						CZECH: 'Pro studenty',
					},
					to: '/public/student',
				},
				{
					title: {
						ENGLISH: 'For alumni',
						CZECH: 'Pro absolvetny',
					},
					to: '/public/alumnus',
				},
				{
					title: {
						ENGLISH: 'For companies',
						CZECH: 'Pro zaměstnavatele',
					},
					to: '/public/company',
				},
			],
			badgeItems: [],
		},
	},
	RegistrationImage: RegistrationImage,
	formFilterColorVariant: 'inverted' as formFilterColorVariantType,
	careerCentreUrl:
		'https://studentskeotazniky.zcu.cz/cs/nech-si-poradit/poradny/karierni-poradna/',
};

export default theme;
