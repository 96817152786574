import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import DOMPurify from 'dompurify';
import BlotFormatter from 'quill-blot-formatter';
import { Quill } from 'react-quill';
import { ReactQueryCacheProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';

import CustomVideo from 'components/form/richtext/CustomVideo';

import { QueryCacheInstance } from 'api';

import App from './App';

// Localization
import 'utils/localization';

// Cross-browser default styling
import 'sanitize.css';

// TODO: Upgrade normalize-url after this issue is resolved. https://github.com/sindresorhus/normalize-url/issues/105

ReactDOM.render(
	<React.StrictMode>
		<ReactQueryCacheProvider queryCache={QueryCacheInstance}>
			<App />
			<ReactQueryDevtools />
		</ReactQueryCacheProvider>
	</React.StrictMode>,
	document.getElementById('root'),
);

// Accessibility testing library
if (process.env.NODE_ENV !== 'production') {
	// Axe
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const axe = require('react-axe');
	axe(React, ReactDOM, 1000);
}
// Sentry
if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: 'https://1d75d9ffe41c43d39c7fa33fd833aea7@sentry.inqool.cz/11',
		ignoreErrors: ['ChunkLoadError', 'SyntaxError'],
	});
}

// Sanitize rich text rule to allow youtube embeds
// https://github.com/cure53/DOMPurify/issues/340#issuecomment-670758980
DOMPurify.addHook('uponSanitizeElement', (node, data) => {
	if (data.tagName === 'iframe') {
		const src = node.getAttribute('src') ?? '';
		if (!src.startsWith('https://www.youtube.com/embed/')) {
			node.parentNode?.removeChild(node);
		}
	}
});
Quill.debug('error');
Quill.register('modules/blotFormatter', BlotFormatter);
Quill.register({
	'formats/video': CustomVideo,
});
