/** @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import '@reach/menu-button/styles.css';
import React, { FC, Fragment } from 'react';
import { Menu, MenuButton, MenuItem, MenuLink } from '@reach/menu-button';
import { useTranslation } from 'react-i18next';

import { Box, Flex } from 'components/styled';
import Text from 'components/styled/Text';
import { NavLink } from 'components/styled/Link';

import { ProfileIconI } from 'assets';
import { JchUser } from 'api';

import { ReactComponent as LogoutIcon } from 'assets/logout-icon.svg';

import useLogout from 'hooks/useLogout';

import { useLocalizedMultistring } from 'utils/localization';

import { MenuAvatar, StyledMenuList } from './styled';

import { UserBadgeItem } from '.';

type Props = {
	user: JchUser;
	badgeItems: UserBadgeItem[];
};

const UserBadge: FC<Props> = ({ user, badgeItems }) => {
	const { t } = useTranslation('components/header/index/');
	const localize = useLocalizedMultistring();

	// Logout
	const handleLogOut = useLogout();

	return (
		<Fragment>
			<Menu>
				{({ isOpen }) => (
					<Fragment>
						<MenuAvatar
							as={MenuButton}
							aria-label={localize({
								CZECH: 'Uživatelské menu',
								ENGLISH: 'User Menu',
							})}
							border
							isOpen={isOpen}
						>
							{user.photoId && (
								<Box
									size="100%"
									css={css`
										background-image: url('/api/jch/files/${user.photoId}');
										background-position: center;
										background-size: cover;
									`}
								/>
							)}
							{!user.photoId && <ProfileIconI size={20} />}
						</MenuAvatar>

						<StyledMenuList>
							{badgeItems.map(({ title, icon, to }, index) => (
								<MenuLink key={`user-badge-item-${index}`} as={NavLink} to={to}>
									<Flex justifyContent="space-between" alignItems="center">
										<Text mr={3} my={0}>
											{localize(title)}
										</Text>
										<Box width={12}>{icon}</Box>
									</Flex>
								</MenuLink>
							))}

							<MenuItem onSelect={handleLogOut}>
								<Flex justifyContent="space-between" alignItems="center">
									<Text
										as="span"
										mr={2}
										css={(theme: Theme) => css`
											&:hover {
												text-decoration: underline;
												text-decoration-color: ${theme.colors.primary};
											}
										`}
									>
										{t('log_out')}
									</Text>
									<LogoutIcon />
								</Flex>
							</MenuItem>
						</StyledMenuList>

						{user.name && (
							<Text
								ml={2}
								p={0}
								as={MenuButton}
								textAlign="right"
								fontSize="md"
								css={(theme: Theme) => css`
									border: 0px;
									background: none;
									max-width: 150px;
									@media (max-width: ${theme.breakpoints[0]}) {
										display: none;
									}
								`}
							>
								{user.name}
							</Text>
						)}
					</Fragment>
				)}
			</Menu>
		</Fragment>
	);
};

export default UserBadge;
