/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { memo, Fragment, useMemo, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

import ErrorBoundary from 'components/ErrorBoundary';
import Header from 'components/header';
import Footer from 'components/footer';
import { Flex } from 'components/styled';
import AppRoutes from 'components/routing/AppRoutes';
import UnsupportedBrowserWarning from 'components/error/UnsupportedBrowserWarning';
import ErrorScreen from 'components/error/ErrorScreen';

import { Loader } from 'modules/loader';

import { ThemeProvider, UniVariant } from 'theme';
import { useLoggedInUserProvider, api } from 'api';

import { useStudentMuniGDPR } from 'api/studentApi';

import { getUserRoutes } from 'auth/routes';

import { isValidBrowser } from 'utils/checkBrowserVersion';

import ToastifyStyles from 'theme/ToastifyStyles';
import GlobalStyles from 'theme/GlobalStyles';
import MUNITheme from 'theme/MUNITheme';
import PLZENTheme from 'theme/PLZENTheme';
import LIBERECTheme from 'theme/LIBERECTheme';
import ZLINTheme from 'theme/ZLINTheme';

const App: React.FC = () => {
	// User
	const { userResponse, UserContextProvider } = useLoggedInUserProvider();

	const user = useMemo(
		() =>
			userResponse.data
				? {
						id: userResponse.data.id,
						name: userResponse.data.name,
						...userResponse.data.context,
				  }
				: undefined,
		[userResponse],
	);

	// University selector
	console.log('Built university:', process.env.REACT_APP_UNIVERSITY);
	const [university, setUniversity] = useState(
		(process.env.REACT_APP_UNIVERSITY ?? 'MUNI') as UniVariant,
	);
	const themeOverride = useMemo(() => {
		switch (university) {
			case 'MUNI':
				return MUNITheme;
			case 'PLZEN':
				return PLZENTheme;
			case 'LIBEREC':
				return LIBERECTheme;
			case 'ZLIN':
				return ZLINTheme;
			default:
				return {};
		}
	}, [university]);

	// MUNI gdpr
	// TODO: call when student is logged in only?
	const gdprResponse = useStudentMuniGDPR();

	// Routes
	const routes = useMemo(
		() => getUserRoutes(university, user, gdprResponse.data),
		[user, university, gdprResponse.data],
	);

	//Google Analytics and FB Pixel initialization for Zlin
	if (process.env.NODE_ENV === 'production' && university === 'ZLIN') {
		ReactGA.initialize('UA-195302951-1');
		ReactPixel.init('959299504089643');
	}

	return (
		<Fragment>
			{!isValidBrowser && <UnsupportedBrowserWarning />}
			<ThemeProvider themeOverrides={themeOverride}>
				<ErrorBoundary>
					<UserContextProvider value={user}>
						<Flex as="main" flexDirection="column" minHeight="100vh">
							<Router>
								<GlobalStyles />
								<ToastifyStyles />
								<Header />
								{(userResponse.isLoading || gdprResponse.isLoading) && (
									<Loader />
								)}
								{userResponse.isError && gdprResponse.isError && (
									<ErrorScreen {...userResponse} />
								)}
								{userResponse.isSuccess && gdprResponse.isSuccess && (
									<AppRoutes routes={routes} />
								)}
								<Footer />
								<ToastContainer
									position="bottom-center"
									newestOnTop={false}
									closeOnClick
									draggable
									pauseOnHover
									transition={Slide}
									autoClose={3000}
									closeButton
								/>
							</Router>
						</Flex>

						{/* Dev university select */}
						{!process.env.REACT_APP_UNIVERSITY && (
							<div
								css={css`
									position: fixed;
									bottom: 0;
									right: 0;
									padding: 16px;
									opacity: 0.25;

									:hover {
										opacity: 1;
									}
								`}
							>
								<select
									value={university}
									onChange={e => setUniversity(e.target.value as UniVariant)}
								>
									{['MUNI', 'PLZEN', 'LIBEREC', 'ZLIN'].map(uni => (
										<option key={uni} value={uni}>
											{uni}
										</option>
									))}
								</select>
								<button
									onClick={() =>
										api()
											.get('reindex')
											.then(r => r.json())
											.then(r => api().post('reindex', { json: r }))
									}
								>
									Reindex
								</button>
							</div>
						)}
					</UserContextProvider>
				</ErrorBoundary>
			</ThemeProvider>
		</Fragment>
	);
};

export default memo(App);
