import { FC } from 'react';
import { FaFacebookF, FaLinkedin } from 'react-icons/fa';

import { Box, Flex } from 'components/styled';
import Text from 'components/styled/Text';
import { ResponsivePadder } from 'components/styled/Wrapper';
import { NavHrefButton } from 'components/styled/Button';

import { ReactComponent as JobTul } from 'assets/liberec/jobtul-logo.svg';

import { useLocalizedMultistring } from 'utils/localization';

const LiberecFooter: FC = () => {
	const localize = useLocalizedMultistring();

	return (
		<Flex as="footer" bg="darkerGrey" flexDirection="column">
			<Box bg="primary" pb={3} />
			<ResponsivePadder
				flexGrow={1}
				flexDirection={['column', 'row']}
				justifyContent={['center', 'space-between']}
				mt={4}
			>
				<Flex flexDirection="column" textAlign={['center', 'left']}>
					<Box color="primary" mb={3}>
						<JobTul />
					</Box>
					<Text my={0}>
						{localize({
							CZECH: 'Kariérové centrum TUL',
							ENGLISH: 'Career Centre TUL',
						})}
					</Text>
					<Text my={0}>Studentská 2 (Budova F3)</Text>
					<Text my={0}>461 17 Liberec 1</Text>
				</Flex>
				<Flex
					flexDirection="column"
					textAlign={['center', 'right']}
					mt={[3, 0]}
				>
					<Box mb={3}>
						<NavHrefButton
							href="https://www.facebook.com/KarieroveTUL/"
							target="_blank"
							variant="primary"
							p={2}
							mr={2}
							minWidth={0}
							size={41}
						>
							<FaFacebookF />
						</NavHrefButton>
						<NavHrefButton
							href="https://www.linkedin.com/company/kari%C3%A9rov%C3%A9-centrum-tul/?viewAsMember=true"
							target="_blank"
							variant="primary"
							p={2}
							minWidth={0}
							size={41}
						>
							<FaLinkedin />
						</NavHrefButton>
					</Box>
					<Text my={0}>job@tul.cz</Text>
					<Text my={0}>+420 725 811 862</Text>
					<Text my={0}>
						{localize({
							CZECH: 'datová schránka:',
							ENGLISH: 'data box:',
						})}{' '}
						td7j9ft
					</Text>
				</Flex>
			</ResponsivePadder>
			<ResponsivePadder
				flexDirection={['column', 'row']}
				justifyContent={['center', 'space-between']}
				textAlign={['center', 'left']}
				mb={2}
				mt={4}
			>
				<Text>
					©{' '}
					{localize({
						CZECH: 'Technická univerzita v Liberci',
						ENGLISH: 'Technical university of Liberec',
					})}
				</Text>
				<Text color="primary">Created by InQool a.s.</Text>
			</ResponsivePadder>
		</Flex>
	);
};

export default LiberecFooter;
