export default {
	// Not found
	NotFound: () => import('modules/notFound'),

	// Public
	Homepage: () => import('modules/public/homepage'),
	Login: () => import('modules/public/login'),
	LoginCompany: () => import('modules/public/login/CompanyLogin'),
	LoginAlumnus: () => import('modules/public/login/AlumnusLogin'),
	LoginAdmin: () => import('modules/public/login/AdminLogin'),
	ForgottenPassword: () => import('modules/public/forgottenPassword'),
	// TODO: Remove after tested. Temporary component for Liberec to test their SMTP server
	TestLiberecMail: () => import('modules/public/testLiberecMail'),
	// ExternalLogin: () => import('modules/public/login/external'),
	ResetPassword: () => import('modules/public/resetPassword'),
	CompanyRegistration: () => import('modules/company/registration'),
	AlumnusRegistration: () => import('modules/alumnus'),

	// External company
	OfferDetailExternal: () => import('modules/offer/detail/RouteExternal'),
	OfferEditExternal: () => import('modules/offer/edit/RouteExternal'),

	// Company
	CompanyDashboard: () => import('modules/company/dashboard'),
	CompanyDetailOwn: () => import('modules/company/detail/RouteOwn'),
	CompanyDetailOther: () => import('modules/company/detail/RouteOther'),
	CompanyProfileEdit: () => import('modules/company/edit'),
	CompanySettings: () => import('modules/company/settings'),
	CompanyOffers: () => import('modules/company/offers'),
	CompanyOrders: () => import('modules/company/orders'),
	CompanyOrderNew: () => import('modules/company/orders/new'),
	CompanyOfferNew: () => import('modules/offer/edit/RouteNew'),
	CompanyOfferEdit: () => import('modules/offer/edit/RouteEdit'),
	CompanySearchStudents: () => import('modules/company/searchStudents'),

	// Student
	StudentDashboard: () => import('modules/student/dashboard'),
	StudentDetailOwn: () => import('modules/student/detail/RouteOwn'),
	StudentDetailOther: () => import('modules/student/detail/RouteOther'),
	StudentProfileEdit: () => import('modules/student/edit'),
	StudentSearchOffers: () =>
		import('modules/search/offers/StudentSearchOffers'),
	// ConsentGDPR: () => import('modules/student/gdpr'),
	StudentSettings: () => import('modules/student/settings'),
	StudentRegistration: () => import('modules/student/registration'),
	MissingUniversityGdpr: () => import('modules/student/MissingUniversityGdpr'),

	// Admin
	AdminDashboard: () => import('modules/admin/dashboard'),
	AdminOrders: () => import('modules/admin/orders'),
	AdminStatistics: () => import('modules/admin/statistics'),
	AdminProducts: () => import('modules/admin/products'),
	AdminStudentProfileEdit: () =>
		import('modules/student/edit/AdminStudentProfileEdit'),
	AdminCompanyProfileEdit: () =>
		import('modules/company/edit/AdminCompanyProfileEdit'),
	AdminSearchOffers: () => import('modules/search/offers/AdminSearchOffers'),
	AdminOfferEdit: () => import('modules/offer/edit/RouteEditAdmin'),
	AdminOfferDetail: () => import('modules/offer/detail/RouteAdmin'),
	AdminConsole: () => import('modules/admin/console'),
	AdminSearchStudents: () => import('modules/admin/searchStudents'),

	// Shared
	OfferDetail: () => import('modules/offer/detail/RouteOther'),
	OfferCandidates: () => import('modules/offer/candidates'),
	SearchCompanies: () => import('modules/search/companies'),
	SearchStudents: () => import('modules/search/students'),
	Notifications: () => import('modules/notifications'),
};
