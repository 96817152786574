/** @jsxImportSource @emotion/react */
import { Global, css } from '@emotion/react';
import React from 'react';

import 'react-toastify/dist/ReactToastify.min.css';

const ToastifyStyles = () => (
	<Global
		styles={theme => css`
			.Toastify__toast--default {
				background: ${theme.colors.primary};
				color: #ffffff;
			}

			.Toastify__toast--error {
				background: ${theme.colors.error};
			}
		`}
	/>
);

export default ToastifyStyles;
