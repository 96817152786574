import React from 'react';
import { useTheme } from '@emotion/react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

import Lazy from 'auth/lazyComps';

import { LazyRoute, RouterItem } from './LazyRoute';
type Props = {
	routes: RouterItem[];
};

const AppRoutes: React.FC<Props> = ({ routes }) => {
	const { pathname, search } = useLocation();
	const { university } = useTheme();

	//Google Analytics and FB Pixel page view tracking
	if (process.env.NODE_ENV === 'production' && university === 'ZLIN') {
		ReactGA.pageview(pathname + search);
		ReactPixel.pageView();
	}

	return (
		<Switch>
			{routes.map(route => {
				if (route.type === 'route') {
					return (
						<Route
							key={`app-route-${route.path}`}
							exact
							path={route.path}
							render={route.render}
						/>
					);
				} else if (route.type === 'redirect') {
					return (
						<Route key={`app-redirect-${route.path}`} exact path={route.path}>
							<Redirect to={route.to} />
						</Route>
					);
				} else {
					throw new Error(`Unhandled route type ${route}`);
				}
			})}

			{/* Not found route */}
			<Route exact render={LazyRoute(Lazy.NotFound)} />
		</Switch>
	);
};

export default AppRoutes;
